import React, { FC } from "react";
import { Button, Grid } from "@epignosis_llc/gnosis";
import { Link } from "react-router-dom";
import { badges } from "./styles";
import { URLS } from "@constants/urls";
import { Badge } from "types/entities";
import BadgeItem from "@views/MyBadges/components/BadgeItem";
import { useUIStore } from "@stores";
import { useApplyTranslations } from "@hooks";

export type BadgesListProps = {
  data: Badge[];
};

const BadgesList: FC<BadgesListProps> = ({ data }) => {
  const { t } = useApplyTranslations();
  const mainNavState = useUIStore((state) => state.mainNavState);
  const isMainNavCollapsed = mainNavState === "collapsed";
  const largeScreens = isMainNavCollapsed ? 4 : 3;

  return (
    <div css={badges}>
      <div className="grid-container">
        <Grid templateColumns={[2, 3, 3, largeScreens, 4]} gap={1}>
          {data.map((badge) => (
            <Grid.Item key={badge.id}>
              <div className="badge-container">
                <BadgeItem {...badge} />
              </div>
            </Grid.Item>
          ))}
        </Grid>
      </div>
      <footer>
        <Button as={Link} to={URLS.user.badges}>
          {t("dashboard.badgeGallery")}
        </Button>
      </footer>
    </div>
  );
};

export default BadgesList;
