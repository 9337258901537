import { useMemo } from "react";

// Hooks, utils etc...
import permissions from "@utils/permissions";
import { useConfigurationStore } from "@stores";

// Constants
import userRoles from "@constants/userRoles";
import { messagesAndDiscussions, userPages } from "../../../constants";
import { useApplyTranslations } from "@hooks";

type useMenuPermissionsProps = {
  messagesAndDiscussionsItems: typeof messagesAndDiscussions;
  userPagesItems: typeof userPages;
  roleOptions: { label: string; value: string }[];
};

const useMenuPermissions = ({
  currentUserRole,
}: {
  currentUserRole: string;
}): useMenuPermissionsProps => {
  // Store Information (global state)
  const { t } = useApplyTranslations();
  const { gamificationSettings, userProfileData, userStatistics } = useConfigurationStore();
  const { certificates, badges, groups, files } = userStatistics ?? {};
  const { available_roles = [], total_invoices } = userProfileData ?? {};

  // Get Permissions
  const { canAccessProfile } = permissions.profilePermissions;
  const { canAccessGamification } = permissions.gamificationPermissions;
  const { canAccessLearnerReports } = permissions.reportsPermissions;
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const { canAccessMessages } = permissions.messagesPermissions;
  const { canJoinAGroup } = permissions.groupPermissions;
  const { canAccessCatalog } = permissions.catalogPermissions;
  const canJoinAGroupPermission = canJoinAGroup() && canAccessCatalog();
  const canViewMyProgress = currentUserRole === userRoles.LEARNER && canAccessLearnerReports();
  const allowAccessMyInfo = canAccessProfile();

  // Assess menu permissions based on derived state from permissions and global state.
  const showMyProfile = allowAccessMyInfo;
  const showMyCertificates = canAccessLearnerReports() && Boolean(certificates?.total);
  const showMyProgress = canViewMyProgress;
  const showMyBadges =
    canAccessGamification() &&
    gamificationSettings?.badges?.enabled &&
    Boolean(badges?.total) &&
    currentUserRole === userRoles.LEARNER;
  const showMyGroups = (allowAccessMyInfo && Boolean(groups?.total)) || canJoinAGroupPermission;
  const showMyFiles = allowAccessMyInfo && Boolean(files?.total);
  const showMyPayments = allowAccessMyInfo && Boolean(total_invoices);

  const showDiscussions = canAccessDiscussions();
  const showMessages = canAccessMessages();

  // Map role options for the radio group
  const roleOptions = available_roles.map((role) => {
    return { label: t(`general.${role}`), value: role };
  });

  // Filter allowed pages based on permissions
  const userPagesItems = useMemo(() => {
    return userPages.filter(({ key }) => {
      if (key === "myProfile") return showMyProfile;
      if (key === "myCertificates") return showMyCertificates;
      if (key === "myProgress") return showMyProgress;
      if (key === "myBadges") return showMyBadges;
      if (key === "myGroups") return showMyGroups;
      if (key === "myFiles") return showMyFiles;
      if (key === "myPayments") return showMyPayments;

      return true;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowAccessMyInfo,
    canAccessGamification,
    canAccessLearnerReports,
    certificates?.total,
    files?.total,
    groups?.total,
    gamificationSettings?.badges?.enabled,
    badges?.total,
    total_invoices,
    currentUserRole,
    canViewMyProgress,
  ]);

  // Filter allowed pages based on permissions
  const messagesAndDiscussionsItems = useMemo(() => {
    return messagesAndDiscussions.filter(({ key }) => {
      if (key === "myMessages") return showMessages;
      if (key === "myDiscussions") return showDiscussions;

      return true;
    });
  }, [showDiscussions, showMessages]);

  return {
    messagesAndDiscussionsItems,
    userPagesItems,
    roleOptions,
  };
};

export default useMenuPermissions;
