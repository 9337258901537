import React, { FC, useState, ReactNode, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { icons } from "@constants/assets";

import { SpotlightSearch, Toolbar } from "@layouts";
import { usePreventIframeEmbedding, useScrollToPageTop } from "@hooks";
import { useConfigurationStore } from "@stores";

type PageProps = {
  pageTitle: string;
  children: ReactNode;
  siteNamePrefix?: boolean;
  toolbar?: ReactNode;
};

const Page: FC<PageProps> = ({ pageTitle, siteNamePrefix = true, children, toolbar = null }) => {
  const [toolbarEl, setToolbarEl] = useState<null | Element>(null);

  const domainSettings = useConfigurationStore((state) => state.domainSettings);
  const { site_name = "", favicon = null, load_in_frame = false } = domainSettings ?? {};
  const shouldHideFromSearchEngines = domainSettings?.hide_from_search_engines;
  const title = siteNamePrefix ? `${site_name} | ${pageTitle}` : pageTitle;
  useScrollToPageTop();
  usePreventIframeEmbedding(load_in_frame);

  useEffect(() => {
    setToolbarEl(document.querySelector("#toolbar"));
  }, [setToolbarEl]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {shouldHideFromSearchEngines && <meta name="robots" content="noindex" />}
          <link rel="icon" href={favicon ?? icons.favicon} />
          <title>{title}</title>
        </Helmet>

        {/* For now he hide the Spotlight for admins and instructors unit its fixed */}
        {toolbarEl && (
          <Toolbar toolbarEl={toolbarEl}>{toolbar ? toolbar : <SpotlightSearch />}</Toolbar>
        )}
        {children}
      </HelmetProvider>
    </>
  );
};

export default Page;
