import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const badges = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  .grid-container {
    height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1.5rem 0.5rem 0rem 1rem;
    padding: 0 1.5rem 0.5rem 1rem;
  }

  .badge-container {
    .criteria {
      visibility: visible;
    }
  }

  footer {
    text-align: center;
    margin: 1rem 0;

    ${mq["sm"]} {
      margin: 1rem 2rem;
      text-align: right;
    }
  }
`;
