import React, { FC, MouseEvent } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { ActionGhostButtonStyles } from "./styles";

type ActionButtonProps = {
  disabled?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const ActionGhostButton: FC<ActionButtonProps> = ({
  onClick,
  disabled = false,
  className,
  children,
  ...rest
}) => {
  return (
    <Button
      css={ActionGhostButtonStyles}
      variant="ghost"
      noGutters
      onClick={onClick}
      disabled={disabled}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ActionGhostButton;
