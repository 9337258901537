import { useEffect, useState } from "react";

// Appends a script tag to the head of the document and resolves when the script is loaded.
export const loadScript = (src: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    // Check if the script is already loaded.
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = src;
    script.crossOrigin = "anonymous";
    script.onload = (): void => resolve();
    script.onerror = (): void => reject(new Error(`Failed to load script ${src}`));
    document.head.appendChild(script);
  });
};

export const useLoadScript = (src: string): { isLoaded: boolean } => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await loadScript(src);
        setIsLoaded(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Failed to load script:", error);
      }
    })();
  }, [src]);

  return {
    isLoaded,
  };
};
