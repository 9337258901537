import React, { FC } from "react";
import { Button, MediaPlayer, Modal } from "@epignosis_llc/gnosis";
import { introVideoModalBody } from "./styles";
import { REACT_PLAYER_CONFIG } from "@components/CourseOverview/constants";
import { t } from "i18next";
import { useUIStore } from "@stores";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";

type IntroVideoModalProps = {
  isOpen: boolean;
  header: string;
  url: string;
  canEditOptions?: boolean;
  onClose: () => void;
};

const IntroVideoModal: FC<IntroVideoModalProps> = ({
  isOpen,
  header,
  url,
  canEditOptions = false,
  onClose,
}) => {
  const { setCourseOptionsState } = useUIStore();

  const handleEditIntroVideo = (): void => {
    onClose();
    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Details,
      scrollId: courseOptionsIds.introVideoOptions,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body css={introVideoModalBody}>
        <MediaPlayer
          id="intro-video"
          className="intro-video"
          type="video"
          src={url}
          config={REACT_PLAYER_CONFIG}
          width="100%"
          height="100%"
          controls
        />
      </Modal.Body>

      <Modal.Footer>
        {canEditOptions && (
          <Button color="primary" onClick={handleEditIntroVideo}>
            {t("courseEdit.editIntroVideo")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default IntroVideoModal;
