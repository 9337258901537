import React, { FC, useEffect, useRef, useState } from "react";
import { Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { IconChevronDownSVG, VerticalEllipsisSVG, GripLinesSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { AccordionStyles } from "./styles";
import ActionGhostButton from "../ActionGhostButton/ActionGhostButton";
import { useIsOverflow } from "@hooks";
import { SerializedStyles } from "@emotion/react";
import { DraggableProvided } from "react-beautiful-dnd";

type AccordionProps = {
  title: string | JSX.Element;
  className?: string;
  testId?: string;
  headerIcon?: JSX.Element;
  options?: DropdownItem[];
  isExpandedDefault?: boolean;
  isReordered?: boolean;
  canEdit?: boolean;
  provided?: DraggableProvided;
  hasBorder?: boolean;
  extraActions?: JSX.Element;
  onDropdownItemClick?: (item: DropdownItem) => void;
  onExpandedChange?: (isExpanded: boolean) => void;
};

const titleClassnames = (isExpanded: boolean): string =>
  classNames({
    "accordion-title": true,
    "is-expanded": isExpanded,
  });

const contentClassnames = (isExpanded: boolean): string =>
  classNames({
    "accordion-content": true,
    "is-expanded": isExpanded,
  });

const iconClassnames = (isExpanded: boolean): string =>
  classNames({
    "accordion-expand-icon": true,
    "is-expanded": isExpanded,
  });

const Accordion: FC<AccordionProps> = (props) => {
  const {
    title,
    headerIcon,
    isExpandedDefault = false,
    options = [],
    provided,
    isReordered,
    canEdit,
    onDropdownItemClick,
    testId = "accordion",
    extraActions,
    children,
    ...rest
  } = props;

  const { hasBorder = false, onExpandedChange, className } = rest;

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);
  const [isHovered, setIsHovered] = useState(false);
  const showReorderIcon = isHovered || isReordered;

  useEffect(() => {
    if (onExpandedChange) {
      onExpandedChange(isExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const renderTitle = (): React.ReactNode => {
    if (typeof title !== "string") {
      return title;
    }

    return (
      <div
        className={titleClassnames(isExpanded)}
        dangerouslySetInnerHTML={{ __html: title }}
        ref={ref}
        onClick={handleHeaderClick}
      />
    );
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const canToggle = !isExpanded ? Boolean(isOverflow || children) : true;

  const handleHeaderClick = (): void => {
    if (canToggle) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      data-testid={testId}
      css={(theme): SerializedStyles => AccordionStyles(theme, { canToggle, hasBorder })}
      className={className}
      onMouseEnter={(): void => {
        if (canEdit) setIsHovered(true);
      }}
      onMouseLeave={(): void => {
        if (canEdit) setIsHovered(false);
      }}
    >
      <div className="accordion-header">
        <div className="accordion-header-content">
          {headerIcon && (
            <div className="accordion-icon" {...provided?.dragHandleProps}>
              {showReorderIcon ? <GripLinesSVG height={16} /> : headerIcon}
            </div>
          )}
          {renderTitle()}
        </div>

        <div className="accordion-actions">
          {extraActions && extraActions}
          {options.length > 0 && (
            <Dropdown
              list={options}
              onListItemSelect={onDropdownItemClick}
              placement="bottom-end"
              fixPlacement
              data-testid="accordion-dropdown-btn"
            >
              <ActionGhostButton disabled={false}>
                <VerticalEllipsisSVG height={16} />
              </ActionGhostButton>
            </Dropdown>
          )}
          <IconChevronDownSVG
            height={32}
            className={iconClassnames(isExpanded)}
            onClick={handleHeaderClick}
            data-testid="accordion-expand-btn"
          />
        </div>
      </div>

      <div className={contentClassnames(isExpanded)}>{children}</div>
    </div>
  );
};

export default Accordion;
