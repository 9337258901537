import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { PointRule, QuestionType, TestOptions } from "types/entities";
import { Tag } from "types/entities/Common";
import { CompletionMethod, EmbedType, LinkedUnit, Size } from "types/entities/Unit";
import { AnswerSet, ImportQuestionType } from "types/entities/Question";
import {
  QuestionRes,
  QuestionUsagesRes,
  QuestionsRes,
  SingleSessionRes,
  TableCSVExportRes,
  YoutubeSearchRes,
  QuestionsImportRes,
  QuestionsImportPreviewRes,
} from "types/responses";

export type UpdateUnitData = {
  name?: string;
  is_active?: boolean;
  content?: string | null;
  secondary_content?: string | null;
  file_id?: number | null;
  url?: string | null;
  completion_method?: {
    type: CompletionMethod["type"];
    // The correct type is number | undefined. Need to add string type to overcome
    // yup issue with conditional validation in nested objects
    time?: number | string;
    question_id?: number | null;
  };
  media_options?: {
    auto_play?: boolean;
    auto_pause?: boolean;
    sync_timer?: boolean;
    show_speed?: boolean;
  };
  captions_file_id?: number | null;
  poster_image_id?: number | null;
  size?: Size;
  embed_type?: EmbedType;
  retain_failed_status?: boolean;
  test_options?: TestOptions & {
    test_password?: string;
    hasTestPassword: boolean; // used only for test_password validation
  };
  survey_options?: {
    show_answers?: boolean;
    mandatory_answers?: boolean;
  };
};

export type QuestionData = {
  text: string;
  answers?: string[]; // required for multiple_choice questions
  correct_answers?: string[]; // required for multiple_choice and ordering questions
  pairs?: string[][]; // required for drag_and_drop questions
  accumulated_points?: number; // min: 0, max: 99999, required for freetext questions
  point_rules?: PointRule[]; // minItems: 1, maxItems: 10, required for freetext questions
  feedback?: string | null; // maxLength: 3500, Required for all except randomized questions
  tags?: string[] | null; // maxItems: 10, Required for all except randomized questions
  type: QuestionType;
  multiple_answers?: boolean | null;
  answer_set?: AnswerSet; // required for likert_scale questions
  children?: string[]; // required for likert_scale questions (likert questions), minItems: 1, maxItems: 10, maxLength: 1000
  custom_answers?: string[]; // required for likert_scale questions when answer_set is "Custom", minItems: 2, maxItems: 7, maxLength: 70
};

export type RandomizedQuestionData = Pick<QuestionData, "type"> & {
  type: QuestionType;
  text?: string;
  pool?: number[]; // required for randomized, minItems: 2
};

type AppearancesData = {
  unitId: string;
  questionId: string;
  appearances: number;
};

export type AnswerObj = { text: string };
export type PairObj = { left: string; right: string };
export type AnswerPairObj = { text: string; isCorrect: boolean };
export type PoolObj = { questionId: number };

export type QuestionFormData = {
  type: QuestionType;
  text: string;
  answerPairs?: AnswerPairObj[];
  answers?: AnswerObj[];
  correct_answers?: AnswerObj[];
  pairs?: PairObj[];
  accumulated_points?: number;
  point_rules?: PointRule[];
  feedback?: string | null;
  tags?: Tag[] | null;
  multiple_answers?: boolean | null;
  answer_set?: AnswerSet;
  children?: AnswerObj[];
  custom_answers?: string[];
};

export type ImportQuestionsFormData = {
  type: ImportQuestionType;
  text: string;
};

export type RandomizedQuestionFormData = {
  type: QuestionType;
  text: string;
  pool: number[];
};

export type QuestionWeight = {
  id: number; // min 1
  weight: number; // min 0, max: 20
  // used only for questions array
  type: QuestionType;
  text: string;
};

export type TestUnitWeightData = {
  questions: QuestionWeight[];
};

export type SetDelayData = {
  mode: string;
  hours?: number;
  days?: number;
};

export type SessionFormData = {
  course_id: number;
  unit_id: number;
  session_type: "classroom" | "webinar";
  name: string;
  color: string;
  capacity: number | null;
  instructor: number;
  location: string | null;
  webinar: boolean;
  webinar_password: boolean;
  duration: number;
  description: string | null;
  multiname: string | null;
  linked: number | null;
  start_datetime: string;
};

/***** UNIT endpoints *****/

export const getLinkedUnits = async (unitId: string): Promise<LinkedUnit[]> => {
  const res = await HttpClient.get(ENDPOINTS.courses.getLinkedUnits(unitId));
  return res.data._data;
};

export const youtubeSearch = async (queryStr = ""): Promise<YoutubeSearchRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.youtubeSearch}${queryStr}`);

  return res.data;
};

export const publishUnit = async (unitId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitPublish(unitId));
};

export const patchUnit = async (unitId: string, data: UpdateUnitData): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.unitUpdate(unitId), data);
};

export const setDelayAvailability = async (
  sectionId: string,
  data: SetDelayData,
): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.setDelayAvailability(sectionId), data);
};

export const deleteBookmarks = async (unitId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.unitDeleteBookmarks(unitId));
};

export const migrateUnit = async (unitId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitMigrate(unitId));
};

/***** TEST UNIT endpoints *****/

export const getTestQuestion = async ({
  questionId,
  preview,
}: {
  questionId: string;
  preview?: boolean;
}): Promise<QuestionRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.getTestQuestion(questionId), {
    params: { preview },
  });

  return res.data;
};

export const getTestQuestions = async (unitId: string): Promise<QuestionsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.testQuestions(unitId));

  return res.data;
};

export const getAllQuestions = async (unitId: string, queryStr = ""): Promise<QuestionsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.getAllQuestions}${queryStr}`, {
    params: { unit_id: unitId },
  });

  return res.data;
};

export const getQuestionUsages = async (
  questionId: string,
  unitId: string,
): Promise<QuestionUsagesRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.questionsUsages(questionId), {
    params: { unit_id: unitId },
  });

  return res.data;
};

export const createTestQuestion = async (
  unitId: string,
  data: QuestionData,
): Promise<QuestionRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.createTestQuestion(unitId), data);

  return res.data;
};

export const createRandomizedQuestion = async (
  unitId: string,
  data: RandomizedQuestionData,
): Promise<QuestionRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.createTestQuestion(unitId), data);

  return res.data;
};

export const importQuestions = async (
  unitId: string,
  data: ImportQuestionsFormData,
): Promise<QuestionsImportRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.importQuestions(unitId), data);

  return res.data;
};

export const importQuestionPreview = async (
  unitId: string,
  data: ImportQuestionsFormData,
): Promise<QuestionsImportPreviewRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.importQuestionPreview(unitId), data);

  return res.data;
};

export const updateQuestionsWeights = async (
  unitId: string,
  data: TestUnitWeightData,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.questionsWeights(unitId), data);
};

export const updateTestQuestion = async (questionId: string, data: QuestionData): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.updateTestQuestion(questionId), data);
};

export const updateRandomizedQuestion = async (
  questionId: string,
  data: RandomizedQuestionData,
): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.updateTestQuestion(questionId), data);
};

export const addExistingQuestion = async (unitId: string, questionId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.addExistingQuestion(unitId, questionId));
};

export const toggleRandomizedQuestion = async (
  randomizedQuestionId: string,
  questionId: string,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.toggleRandomizedQuestion(randomizedQuestionId), {
    question_id: questionId,
  });
};

export const changeQuestionAppearances = async ({
  unitId,
  questionId,
  appearances,
}: AppearancesData): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.questionAppearances(unitId, questionId), { appearances });
};

export const removeExistingQuestion = async (unitId: string, questionId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.removeExistingQuestion(unitId, questionId));
};

export const deleteTestQuestion = async (questionId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.deleteTestQuestion(questionId));
};

export const getExistingQuestionsTableExport = async (
  unitId: string,
  queryStr = "",
): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.tableExports.existingQuestions(unitId)}${queryStr}`,
  );

  return res.data;
};

/***** ILT UNIT endpoints *****/

export const createIltSession = async (data: SessionFormData): Promise<SingleSessionRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.unitIlt.createSession, data);

  return res.data;
};

export const cloneIltSession = async (sessionId: string): Promise<SingleSessionRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.unitIlt.cloneSession(sessionId));

  return res.data;
};

export const updateIltSession = async (sessionId: string, data: SessionFormData): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitIlt.updateSession(sessionId), data);
};

export const deleteIltSession = async (sessionId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.unitIlt.deleteSession(sessionId));
};
