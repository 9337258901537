import React, { FC, useState } from "react";
import { useQueryClient } from "react-query";
import { SerializedStyles } from "@emotion/react";
import { useResponsive } from "ahooks";

// Styles
import { profileSubMenuStyles } from "./ProfileSubMenu.styles";

// Components
import { UserPages } from "../UserPages";
import { UserDetails } from "../UserDetails";
import { BranchesMobile } from "../../../BranchesMobile";
import { UserRoleSwitch } from "../UserRoleSwitch";

// Hooks, utils etc...
import authService from "@utils/services/AuthService";
import { useLogout } from "@hooks";
import { useMenuPermissions, useReturnToLegacy } from "./hooks";

// Constants
import userRoles from "@constants/userRoles";
import {
  branches,
  logout,
  returnToLegacy,
  impersonation,
  referral,
  watchDemo,
} from "../../constants";
import { useBranches } from "../../../../hooks/useBranches";
import { useConfigurationStore } from "@stores";
import useWatchDemo from "../../../WatchDemoButton/useWatchDemo";

type SubMenuProps = {
  setIsActive: (isActive: boolean) => void;
  isImpersonated: boolean | undefined;
};

const ProfileSubMenu: FC<SubMenuProps> = ({ isImpersonated, setIsActive }) => {
  const [showBranches, setShowBranches] = useState(false);
  const { handleReturnToLegacy } = useReturnToLegacy(); // handleReturnToLegacy is used both for leaving beta and impersonation.
  const { handleLogOut } = useLogout();
  const currentUserRole = authService.getDefaultRole();
  const { userProfileData } = useConfigurationStore();

  const canAccessReferral = userProfileData?.policies.can_access_referral ?? false;
  const canWatchDemo = userProfileData?.policies.can_watch_demo ?? false;

  const { handleWatchDemoClick } = useWatchDemo();
  const { md, lg } = useResponsive();

  const { messagesAndDiscussionsItems, userPagesItems, roleOptions } = useMenuPermissions({
    currentUserRole,
  });
  const { branchesOptions } = useBranches();
  const shouldRenderBranches = branchesOptions && branchesOptions.length > 0;

  // queryClient and current user role
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (!md) document.body.style.setProperty("overflow", "hidden");

    return () => {
      if (!md) document.body.style.setProperty("overflow", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleSelect = (selectedValue: string | React.FormEvent<HTMLFieldSetElement>): void => {
    if (selectedValue !== currentUserRole) {
      authService.setDefaultRole(selectedValue.toString() ?? userRoles.LEARNER);
      queryClient.invalidateQueries();
      setIsActive(false);
    }
  };

  return (
    <div
      css={(theme): SerializedStyles => profileSubMenuStyles(theme, { isMobile: !md })}
      className="sub-menu-container"
    >
      <div className="sub-menu-content">
        {/* User details */}
        {!md && (
          <>
            <UserDetails
              isInnerUserInfo={true}
              showRole={false}
              handleCloseMenu={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}

        {/* Impersonation - back to admin  */}
        {!md && isImpersonated && (
          <>
            <UserPages pageItems={impersonation} handleCallback={handleReturnToLegacy} />
            <div className="divider" />
          </>
        )}

        {/* Role switching  */}
        <UserRoleSwitch
          roleOptions={roleOptions}
          currentUserRole={currentUserRole}
          onRoleSelect={handleRoleSelect}
          isImpersonated={isImpersonated}
        />

        {/* Switch Branch  */}
        {!md && shouldRenderBranches && (
          <>
            <UserPages
              hasSubMenu={true}
              pageItems={branches}
              handleCallback={(): void => setShowBranches(true)}
            />
            {showBranches && (
              <BranchesMobile isOpen={showBranches} onClose={(): void => setShowBranches(false)} />
            )}
            <div className="divider" />
          </>
        )}

        {/* Messages & Discussions (MOBILE ONLY) */}
        {!md && messagesAndDiscussionsItems && (
          <>
            <UserPages
              pageItems={messagesAndDiscussionsItems}
              handleCloseMenu={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}

        {/* Menu Pages */}
        {userPagesItems && (
          <>
            <UserPages
              pageItems={userPagesItems}
              handleCloseMenu={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}

        {/* Return to legacy UI */}
        <UserPages pageItems={returnToLegacy} handleCallback={handleReturnToLegacy} />
        <div className="divider" />

        {/* Referral */}

        {canAccessReferral && (
          <>
            <UserPages pageItems={referral} handleCloseMenu={(): void => setIsActive(false)} />
            <div className="divider" />
          </>
        )}

        {!lg && canWatchDemo && (
          <>
            <UserPages pageItems={watchDemo} handleCallback={handleWatchDemoClick} />
            <div className="divider" />
          </>
        )}

        {/* Logout button */}
        <UserPages pageItems={logout} handleCallback={handleLogOut} />
      </div>
    </div>
  );
};

export default ProfileSubMenu;
