export const YOUTUBE_VIMEO_URL_REGEX =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=.{11}(\S*)?|vimeo\.com\/\d{8,9}(\S*)?)$/i;

export const ULR_WITH_PROTOCOL_REGEX = /^(http|https):\/\/[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+([/?].*)?$/;

export const DOMAIN_REGEX =
  /^~?[a-z0-9-\\.]{1,100}\.[a-z]{1,20}(?:, *~?[a-z0-9-\\.]{1,100}\.[a-z]{1,20})*$/;

export const USERNAME_REGEX = /^(?=(_*\w+(\w*[._@'+-]*\w*)*))\1*$/;
export const HEX_COLOR_REGEX = /^#[a-fA-F\d]{6}$/;
