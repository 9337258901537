// Packages or third-party libraries
import React, { FC } from "react";
import { Grid } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

// Styles
import { iltSessionDetailsStyles } from "./styles";

// Components
import { PasswordTooltip } from "@components";
import { IltTitle } from "./IltTitle";
import IltInfoItem from "./IltInfoItem";

// Utils
import { formatUtcDate, getFormattedUserName } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

// Other imports
import { Session, SessionItem } from "types/entities/Unit";

type IltSessionDetailsProps = {
  sessionItem: SessionItem;
  session: Session;
  maxCols: number;
  showTitle: boolean;
  isLive: boolean;
};

const IltSessionDetails: FC<IltSessionDetailsProps> = ({
  sessionItem,
  session,
  maxCols,
  showTitle,
  isLive,
  children,
}) => {
  const { t } = useApplyTranslations();
  const { lg, xl, xxl } = useResponsive();
  const { showUnitSidebar } = useUIStore();

  const { start_datetime, end_datetime, location, type, name, join_password, instructor } =
    sessionItem;
  const { users_registered, capacity } = session;
  const startTime = formatUtcDate(start_datetime, "time");
  const endTime = formatUtcDate(end_datetime, "time");
  const instructorName = instructor
    ? getFormattedUserName({
        name: instructor.name,
        surname: instructor.surname,
        login: instructor.login,
      })
    : "-";
  const isMobile = !lg;
  const isLGScreen = lg && !xl;
  const isXLScreen = xl && !xxl;
  const showChildrenInTitle = isLGScreen || (isXLScreen && showUnitSidebar);

  return (
    <div css={iltSessionDetailsStyles}>
      {/* not show multiple session icon */}
      {!isMobile && showTitle && (
        <div className="title-container">
          <IltTitle
            name={name}
            showMultiSession={false}
            isLive={isLive}
            endDatetime={end_datetime}
          />
          {children && showChildrenInTitle && (
            <Grid.Item className="children">{children}</Grid.Item>
          )}
        </div>
      )}

      <Grid
        templateColumns={[1, 2, 2, 2, showChildrenInTitle ? 4 : maxCols]}
        gap={!isMobile ? 1 : 0.5}
      >
        <Grid.Item>
          <IltInfoItem label={t("ilt.time")} content={`${startTime} - ${endTime}`} />
        </Grid.Item>
        <Grid.Item>
          <IltInfoItem label={t("general.instructor")} content={instructorName} />
        </Grid.Item>
        <Grid.Item>
          <IltInfoItem
            label={t("general.capacity")}
            content={`${users_registered} / ${capacity ?? t("general.unlimited")}`}
          />
        </Grid.Item>
        <Grid.Item>
          <IltInfoItem
            label={t("ilt.location")}
            content={
              type === "classroom" ? (
                <>{location ? location : t("general.inPerson")}</>
              ) : (
                <div className="webinar-location-container">
                  {t("dashboard.today.online")}
                  {join_password && (
                    <div className="password">
                      <PasswordTooltip
                        password={join_password}
                        placement="top-start"
                        buttonColor="primary"
                      />
                    </div>
                  )}
                </div>
              )
            }
          />
        </Grid.Item>
        {children && !isMobile && !showChildrenInTitle && (
          <Grid.Item className="children">{children}</Grid.Item>
        )}
      </Grid>
    </div>
  );
};

export default IltSessionDetails;
