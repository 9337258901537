import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { tocContentWrapperStyles } from "./styles";

type TocContentWrapperProps = {
  hasUnits: boolean;
};

const TocContentWrapper: FC<TocContentWrapperProps> = ({ hasUnits, children }) => {
  return (
    <div css={(theme): SerializedStyles => tocContentWrapperStyles(theme, { hasUnits })}>
      <div className="toc-content-wrapper">{children}</div>
    </div>
  );
};

export default TocContentWrapper;
