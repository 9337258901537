import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["timeline"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    iconColor: protectColorContrast("darker", colors.primary.base),
    dateColor: colors.secondary.base,
    borderColor: colors.secondary.lighter,
    deleteActionBackground: colors.red.light,
    deleteActionText: colors.white,
    primary: {
      background: hexToRGBA(colors.blue.lightest, 0.25),
      color: colors.blue.base,
    },
    success: {
      background: hexToRGBA(colors.green.lightest, 0.25),
      color: colors.green.base,
    },
    danger: {
      background: hexToRGBA(colors.red.lightest, 0.25),
      color: colors.red.base,
    },
    warning: {
      background: colors.orange.lighter,
      color: colors.orange.base,
    },
  };
};
