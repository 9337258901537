import React, { FC, useEffect, useState } from "react";
import { Button, Grid, InputError, Select } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";
import { t } from "@utils/i18n";
import { CourseRulesFormData, ScoreUnit } from "@views/CourseEdit/types";
import { ScoreRule } from "types/entities/Courses";
import { ScoreCalculatorSVG } from "@epignosis_llc/gnosis/icons";
import { SelectOption } from "types/common";
import { TextWithIcon } from "@components";
import { MyUnit } from "types/entities";
import { mapUnitsToSelectOption } from "@views/CourseEdit/helpers";
import UnitWeights from "./UnitWeights";
import classNames from "classnames";
import { useApplyTranslations } from "@hooks";

type ScoreRulesProps = {
  form: UseFormReturn<CourseRulesFormData>;
  units: MyUnit[];
};

const SPECIFIC_UNIT_TYPES = ["test", "assignment", "ilt", "scorm", "xapi", "cmi5"];

const getScoreRuleOptions = (units: MyUnit[]): SelectOption[] => {
  const completionRuleOptions: SelectOption[] = [
    {
      label: t("courseEdit.scoreRules.allTestsAndAssignments"),
      value: ScoreRule.AllTestsAndAssignments,
    },
    {
      label: t("courseEdit.scoreRules.testsOnly"),
      value: ScoreRule.TestsOnly,
    },
  ];

  const hasUnits = units.some(({ type }) => SPECIFIC_UNIT_TYPES.includes(type));

  if (hasUnits) {
    completionRuleOptions.push({
      label: t("courseEdit.scoreRules.specificUnits"),
      value: ScoreRule.SpecificUnits,
    });
  }

  return completionRuleOptions;
};

const getSpecificUnitsOptions = (units: MyUnit[]): SelectOption[] => {
  return mapUnitsToSelectOption(units.filter(({ type }) => SPECIFIC_UNIT_TYPES.includes(type)));
};

const getScoreUnitsFromOptions = (
  scoreUnits: ScoreUnit[],
  options: SelectOption[],
): ScoreUnit[] => {
  return options.reduce((array, option) => {
    const scoreUnit = scoreUnits.find(({ id }) => id.toString() === option.value);

    if (scoreUnit) {
      const { id, weight } = scoreUnit;
      array.push({ id, weight });
    } else {
      array.push({ id: Number(option.value), weight: 1 });
    }
    return array;
  }, [] as ScoreUnit[]);
};

const ScoreRules: FC<ScoreRulesProps> = ({ form, units }) => {
  const { t } = useApplyTranslations();
  const { control, watch, setValue } = form;
  const scoreRuleWatch = watch("score_rule");
  const isSpecificUnitsSelected = scoreRuleWatch === ScoreRule.SpecificUnits;
  const scoreRuleOptions = getScoreRuleOptions(units);
  const specificUnitsOptions = getSpecificUnitsOptions(units);
  const scoreUnits = watch("score_units") ?? [];
  const canSetUnitWeights = scoreUnits?.length > 1;

  const [showUnitWeights, setShowUnitWeights] = useState(false);
  const setUnitWeightsBtnClassNames = classNames({ "is-pressed": showUnitWeights });

  const handleWeightsChange = (newScoreUnits: ScoreUnit[]): void => {
    setValue("score_units", newScoreUnits);
  };

  // hide set weights section when selected unit are less than one
  useEffect(() => {
    if (!canSetUnitWeights) setShowUnitWeights(false);
  }, [canSetUnitWeights]);

  return (
    <div>
      <TextWithIcon
        icon={<ScoreCalculatorSVG height={32} />}
        label={t("courseEdit.scoreRules.title")}
      />

      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={[1, 1, 1, 2]} className="half-column">
          <Controller
            name="score_rule"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              const selectedValue = scoreRuleOptions.find((option) => option.value === value);

              return (
                <Select
                  id="completion-rule"
                  value={selectedValue}
                  options={scoreRuleOptions}
                  label={t("courseEdit.scoreRules.calculateScoreBy")}
                  onChange={(option): void => {
                    const { value } = option as SelectOption;
                    onChange(value);

                    // reset fields when score rule changes
                    setValue("score_units", undefined);
                  }}
                />
              );
            }}
          />
        </Grid.Item>

        {isSpecificUnitsSelected && (
          <Grid.Item colSpan={[1, 1, 1, 2]} className="half-column">
            <Controller
              name="score_units"
              control={control}
              render={({
                field: { onChange, value: scoreUnits },
                fieldState: { error },
              }): JSX.Element => {
                const selectedIds = scoreUnits ? scoreUnits.map(({ id }) => id.toString()) : [];
                const selectedValues = scoreUnits
                  ? specificUnitsOptions.filter((option) => selectedIds.includes(option.value))
                  : [];

                return (
                  <>
                    <Select
                      id="score-units"
                      value={selectedValues}
                      options={specificUnitsOptions}
                      label={t("general.units")}
                      placeholder={t("general.selectUnits", { count: 2 })}
                      innerPlaceholder={t("general.selectUnits", { count: 2 })}
                      hasInnerSearch
                      isMulti
                      onChange={(options): void => {
                        const selectedOptions = options as SelectOption[];

                        const newScoreUnits = getScoreUnitsFromOptions(
                          scoreUnits ?? [],
                          selectedOptions,
                        );

                        onChange(newScoreUnits);
                      }}
                    />
                    {error && <InputError>{error.message}</InputError>}
                  </>
                );
              }}
            />
          </Grid.Item>
        )}

        {canSetUnitWeights && (
          <Grid.Item colSpan={[1, 1, 1, 2]}>
            <Button
              variant="ghost"
              noGutters
              className={setUnitWeightsBtnClassNames}
              onClick={(): void => setShowUnitWeights(true)}
            >
              {t("courseEdit.setUnitWeights")}
            </Button>
          </Grid.Item>
        )}

        {showUnitWeights && (
          <Grid.Item colSpan={[1, 1, 1, 2]}>
            <UnitWeights
              scoreUnits={scoreUnits}
              specificUnitsOptions={specificUnitsOptions}
              onChange={handleWeightsChange}
              onClose={(): void => setShowUnitWeights(false)}
            />
          </Grid.Item>
        )}
      </Grid>
    </div>
  );
};

export default ScoreRules;
