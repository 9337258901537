import { SerializedStyles } from "@emotion/react";
import React, { FC, CSSProperties } from "react";
import { tabWidget } from "./styles";

type TabWidgetProps = {
  style?: CSSProperties;
  isGamificationDrawer?: boolean;
};

const TabWidget: FC<TabWidgetProps> = ({ style, isGamificationDrawer = false, children }) => {
  return (
    <div css={(): SerializedStyles => tabWidget(isGamificationDrawer)} style={style}>
      {children}
    </div>
  );
};

export default TabWidget;
