import React, { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";
import PubSub from "pubsub-js";
import { Heading, Text } from "@epignosis_llc/gnosis";

export type GamificationNotificationOptions = ToastOptions & {
  icon: JSX.Element;
  heading: ReactNode;
  content: ReactNode;
  duration?: number;
};

export const GAMIFICATION_NOTIFICATIONS = "GAMIFICATION NOTIFICATIONS";

const gamificationNotificationSubscriber = (
  _channel: string,
  options: GamificationNotificationOptions,
): void => {
  const { type = "success", icon, heading, content, duration = 2500, ...rest } = options;

  toast[type](
    <div className="gamification-notification-container">
      <span className="prefix-icon-badge">{icon}</span>
      <div className="gamification-notification-content">
        <Heading as="h5" size="xs" className="heading">
          {heading}
        </Heading>
        <Text as="div" fontSize="xs">
          {content}
        </Text>
      </div>
    </div>,
    {
      closeButton: false,
      position: "bottom-right",
      hideProgressBar: true,
      autoClose: duration,
      theme: "light",
      ...rest,
    },
  );
};

export const gamificationNotificationChannel = PubSub.subscribe(
  GAMIFICATION_NOTIFICATIONS,
  gamificationNotificationSubscriber,
);
