import React, { FC } from "react";
import { tocStyles } from "./styles";
import Top, { topProps } from "./components/Top";
import Header, { HeaderProps } from "./components/Header";
import ListContainer, { ListContainerProps } from "./components/ListContainer";
import ListTitle, { ListTitleProps } from "./components/ListTitle";
import ListItem, { ListItemProps } from "./components/ListItem";

type ToCCompoundProps = {
  Top: FC<topProps>;
  Header: FC<HeaderProps>;
  ListContainer: FC<ListContainerProps>;
  ListTitle: FC<ListTitleProps>;
  ListItem: FC<ListItemProps>;
};

const ToC: FC & ToCCompoundProps = ({ children }) => <div css={tocStyles}>{children}</div>;

ToC.Top = Top;
ToC.Header = Header;
ToC.ListContainer = ListContainer;
ToC.ListTitle = ListTitle;
ToC.ListItem = ListItem;

export default ToC;
