import { SerializedStyles } from "@emotion/react";
import React from "react";
import { announcementsContainer } from "./styles";
import { Input, InputError, Text, Textarea } from "@epignosis_llc/gnosis";
import { Control, Controller, FieldErrors, FieldValues, Path } from "react-hook-form";
import classNames from "classnames";
import { useApplyTranslations } from "@hooks";

type DrawerInputFieldProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  domainURL?: string;
  label: string;
  subtitle?: string;
  isDisabled?: boolean;
  type?: string;
};

const drawerInputFieldClassName = (isTextArea: boolean): string =>
  classNames({
    "drawer-input-field": true,
    "is-textarea": isTextArea,
  });

const DrawerInputField = <T extends FieldValues>({
  name,
  control,
  subtitle,
  label,
  type = "text",
  errors,
  domainURL,
  isDisabled = false,
}: DrawerInputFieldProps<T>): JSX.Element => {
  const { t } = useApplyTranslations();

  return (
    <div css={(theme): SerializedStyles => announcementsContainer(theme)}>
      <form className="form-container">
        <div className="domain-name-container">
          <div className={drawerInputFieldClassName(type !== "text")}>
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, value } }): JSX.Element =>
                type === "text" ? (
                  <Input
                    name={name}
                    status={errors.name ? "error" : "valid"}
                    type="text"
                    value={value}
                    disabled={isDisabled}
                    id="host"
                    label={label}
                    placeholder={t("general.typeHere")}
                    onChange={(text): void => {
                      onChange(text);
                    }}
                  />
                ) : (
                  <Textarea
                    name={name}
                    status={errors.name ? "error" : "valid"}
                    type="text"
                    value={value}
                    disabled={isDisabled}
                    id="host"
                    label={label}
                    placeholder={t("general.typeHere")}
                    onChange={(text): void => {
                      onChange(text);
                    }}
                  />
                )
              }
            />
          </div>
          {domainURL && (
            <Text fontSize="sm" className="text-container">
              {domainURL}
            </Text>
          )}
        </div>

        {errors[name.toString()]?.message && (
          <InputError>{errors?.[name.toString()]?.message}</InputError>
        )}
      </form>
      {Boolean(subtitle) && (
        <Text fontSize={"sm"} className="subtitle">
          {subtitle}
        </Text>
      )}
    </div>
  );
};

export default DrawerInputField;
