import React, { FC } from "react";
import { useResponsive } from "ahooks";
import { TopBarMenuStyles } from "./TopBarMenu.styles";

// Components
import { ProfileMenu } from "./components/ProfileMenu";
import { MessagesMenu } from "./components/MessagesMenu";
import { BranchesMenu } from "./components/BranchesMenu";
import { DiscussionsMenu } from "./components/DiscussionsMenu";
import { ImpersonationBackButton } from "./components/ImpersonationBackButton";
import { WatchDemoButton } from "./components/WatchDemoButton";

// Stores
import { useConfigurationStore } from "@stores";

// Permissions
import permissions from "@utils/permissions";

const TopBarMenu: FC = () => {
  // Responsive breakpoint. sm means until 576px.
  const { md } = useResponsive();
  const { userProfileData } = useConfigurationStore();

  // Permissions. Some checks are initiated at component level (ex: branches menu, Profile sub-menu items).
  const showImpersonationBackButton = userProfileData?.impersonated;
  const canWatchDemo = userProfileData?.policies.can_watch_demo;
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const { canAccessMessages } = permissions.messagesPermissions;
  const showDiscussions = canAccessDiscussions() && md;
  const showMessages = canAccessMessages() && md;
  const showBranches = md;

  return (
    <section data-testid="topbar-menu" css={TopBarMenuStyles}>
      {canWatchDemo && <WatchDemoButton />}
      {showMessages && <MessagesMenu />}
      {showDiscussions && <DiscussionsMenu />}
      {showBranches && <BranchesMenu />}
      {md && showImpersonationBackButton && <ImpersonationBackButton />}
      <ProfileMenu isImpersonated={showImpersonationBackButton} />
    </section>
  );
};

export default TopBarMenu;
