/* eslint-disable no-console */
// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Trans } from "react-i18next";
import { Button, Text } from "@epignosis_llc/gnosis";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";

// Components
import { ActionDrawer, Skeletons } from "@components";
import { DynamicGaugeChart, Link } from "@components/ReusableComponents";
import CustomActionButtons from "./CustomActionButtons";
import CreditBreakdownDrawer from "./CreditBreakdownDrawer/CreditBreakdownDrawer";

// Styles
import { AICreditsDrawerContainer } from "./styles";

// Icons
import { OpenedMessageSVG } from "@epignosis_llc/gnosis/icons";

// Utils
import { generalNotification } from "@utils/helpers";
import permissions from "@utils/permissions";

// API
import { getCraftCredits, postCreditRequest } from "./api";

// Constants
import queryKeys from "@constants/queryKeys";
import { handleAICreditsErrors } from "@errors/errors";

// Hooks
import { useApplyTranslations } from "@hooks";
import { getSubscriptionInterval2 } from "@components/Course/helpers";
import { resolveTooltipContent } from "./helpers";

type AICreditsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AICreditsDrawer: FC<AICreditsDrawerProps> = ({ isOpen, onClose }) => {
  const { t } = useApplyTranslations();
  const [isAICreditBreakdownDrawerOpen, setIsAICreditBreakdownDrawerOpen] = useState(false);

  // permissions
  const { canUpdateCourseUnits } = permissions.coursesPermissions;
  const { canAccessSubscription } = permissions.accountPermissions;

  const canRequestCredits = canUpdateCourseUnits() && !canAccessSubscription();
  const shouldShowUpgradeButton = canAccessSubscription();

  const {
    data: craftCredits,
    status,
    error,
  } = useQuery([queryKeys.craft.credits], getCraftCredits, {
    select: (res) => res._data,
    onError: (error: AxiosError) => {
      handleAICreditsErrors(error);
    },
  });

  const {
    ai_credits: balance,
    total_ai_credits: totalCredits,
    subscription_interval: subscriptionInterval,
    next_billing_date: nextBillingDate,
  } = craftCredits ?? {};

  const resetType = getSubscriptionInterval2(subscriptionInterval ?? null);

  const { mutate: requestForCreditsMutation } = useMutation(
    [queryKeys.craft.creditRequest],
    postCreditRequest,
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("AICredits.requestSendSuccessfully")}</p>);
      },
      onError: (error: AxiosError) => {
        handleAICreditsErrors(error);
      },
    },
  );

  const handleRequestForCredits = (): void => {
    canRequestCredits && requestForCreditsMutation();
  };

  const handleOpenCreditBreakdownDrawer = (): void => {
    setIsAICreditBreakdownDrawerOpen(true);
  };

  const handleCloseCreditBreakdownDrawer = (): void => {
    setIsAICreditBreakdownDrawerOpen(false);
  };

  const tooltipContent = resolveTooltipContent(resetType, balance, nextBillingDate);

  return (
    <>
      <ActionDrawer
        isOpen={isOpen}
        headerTitle={t("AICredits.AICreditBalance")}
        size="md"
        onClose={onClose}
        customFooter={
          <CustomActionButtons
            onClose={onClose}
            shouldShowUpgradeButton={shouldShowUpgradeButton}
          />
        }
        tooltipContent={tooltipContent}
      >
        <div css={AICreditsDrawerContainer}>
          <div className="credits-container">
            <Skeletons.Loader status={status} error={error}>
              <DynamicGaugeChart
                title="Balance"
                score={balance}
                maxScore={totalCredits ?? 0}
                isPassed
                isFullCircle
              />
            </Skeletons.Loader>
          </div>
          <div className="AI-text-container">
            <Text as="div" fontSize="md" weight="700">
              {t("AICredits.AICredits")}
            </Text>
            <Text as="div" fontSize="sm">
              <Trans i18nKey="AICredits.creditExplanation">
                <Link to="#" onClick={handleOpenCreditBreakdownDrawer} className="link">
                  {t("general.open").toLocaleLowerCase()}
                </Link>
              </Trans>
            </Text>
          </div>
          {canRequestCredits && (
            <div className="request-container">
              <Text as="div" fontSize="sm" weight="700">
                {t("AICredits.requestMoreCredits")}
              </Text>
              <div>
                <Button
                  iconBefore={OpenedMessageSVG}
                  variant="outline"
                  onClick={handleRequestForCredits}
                >
                  <Text fontSize="xs">{t("AICredits.sendRequest")}</Text>
                </Button>
              </div>
            </div>
          )}
        </div>
      </ActionDrawer>
      {isAICreditBreakdownDrawerOpen && (
        <CreditBreakdownDrawer
          isOpen={isAICreditBreakdownDrawerOpen}
          onClose={handleCloseCreditBreakdownDrawer}
        />
      )}
    </>
  );
};

export default AICreditsDrawer;
