import React, { FC } from "react";
import { t } from "i18next";
import { Grid, Input, InputError } from "@epignosis_llc/gnosis";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { invitationDrawerStyles } from "./styles";
import { SharedNotificationData, postSharedNotification } from "@views/CourseEdit/api";
import { SharedNotificationValidationSchema } from "@views/CourseEdit/validations";
import { useConfigurationStore } from "@stores";
import { generalNotification, getFormattedUserName, handleEmailErrors } from "@utils/helpers";
import { Course } from "types/entities";
import queryKeys from "@constants/queryKeys";
import { getCoursePublicUrl } from "@views/CourseEdit";
import { ActionDrawer, CreatableInput, Editor } from "@components";

type InvitationsDrawerProps = {
  isOpen: boolean;
  course: Course;
  onClose: () => void;
};

const toolbarButtons = ["bold", "italic", "underline"];

const InvitationsDrawer: FC<InvitationsDrawerProps> = ({ isOpen, course, onClose }) => {
  const userProfileData = useConfigurationStore((state) => state.userProfileData);
  const { name = "", surname = "", login = "" } = userProfileData ?? {};
  const userName = getFormattedUserName({ name, surname, login });
  const { id, public_key } = course;
  const courseId = id.toString();
  const publicUrl = getCoursePublicUrl(public_key as string);

  const {
    register,
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<SharedNotificationData>({
    mode: "onChange",
    resolver: yupResolver(SharedNotificationValidationSchema),
    defaultValues: {
      emails: [],
      subject: t("courseEdit.sharedNotification.subject"),
      message: t("courseEdit.sharedNotification.message", {
        userName,
        courseName: course.name,
        url: publicUrl,
      }),
    },
  });

  const { mutate: postSharedNotificationMutation, isLoading: postSharedNotificationLoading } =
    useMutation(
      [queryKeys.courses.sharedNotification],
      (data: SharedNotificationData) => postSharedNotification(courseId, data),
      {
        onSuccess: (_, variables) => {
          generalNotification(
            "success",
            <p>
              {t("courseEdit.sharedNotification.successMessage", {
                count: variables.emails.length,
              })}
            </p>,
          );
          onClose();
        },
        onError: () => {
          generalNotification("error", <p>{t("general.somethingWentWrongPleaseTryAgain")}</p>);
        },
      },
    );

  const handleOnApply = (data: SharedNotificationData): void => {
    postSharedNotificationMutation(data);
  };

  return (
    <ActionDrawer
      isOpen={isOpen}
      headerTitle={t("general.sendInvitations")}
      size="md"
      hasBackButton
      actionButton={{
        text: t("messages.send"),
        isDisabled: !isValid,
        isLoading: postSharedNotificationLoading,
      }}
      onClose={onClose}
      onApply={handleSubmit(handleOnApply)}
    >
      <Grid templateColumns={1} gap={1.5} css={invitationDrawerStyles}>
        <Grid.Item colSpan={1} className="overflow-hidden">
          <Controller
            name="emails"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              return (
                <CreatableInput
                  label={t("messages.sentTo")}
                  placeholder="Start typing to add recipients"
                  tooltipContent={t("courseEdit.sharedNotification.tooltip")}
                  onChange={(emails): void => {
                    if (JSON.stringify(emails) !== JSON.stringify(value)) onChange(emails);
                  }}
                />
              );
            }}
          />
          {handleEmailErrors(errors, "emails")}
        </Grid.Item>

        <Grid.Item colSpan={1}>
          <Input id="invitation-subject" label={t("messages.subject")} {...register("subject")} />
          {errors?.subject && <InputError>{errors.subject.message}</InputError>}
        </Grid.Item>

        <Grid.Item colSpan={1}>
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              return (
                <Editor
                  toolbarButtons={toolbarButtons}
                  id="invitation-message"
                  model={value}
                  status={errors.message && "error"}
                  onChange={onChange}
                />
              );
            }}
          />
          {errors?.message && <InputError>{errors.message.message}</InputError>}
        </Grid.Item>
      </Grid>
    </ActionDrawer>
  );
};

export default InvitationsDrawer;
