export const TALENT_LMS_HELP_LINKS = {
  ACTIVATE_COURSE_SUMMARY_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651413980956",
  COURSE_RATING_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651403860380",
  COURSE_SHARE: "https://help.talentlms.com/hc/en-us/articles/9652335475484",
  COURSE_LOCKED: "https://help.talentlms.com/hc/en-us/articles/9651401020956",
  HIDE_BRANCH_COURSES_FROM_CATALOG_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651408298140",
  IMPORT_EXPORT_SFTP_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/11811232680348-How-to-import-export-data-via-an-SFTP-server-in-TalentLMS-Beta",
  GAMIFICATION_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651488201628",
  PASSWORD_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9652285809564",
  PREVENT_MULTIPLE_LOGINS_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9652269759900",
  SECURE_CONTENT_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651489862812",
  API_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651527213468",
  XAPI_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651511869724",
  BAMBOO_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651541673372",
  INTERCOM_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651494688412",
  INSPECTLET_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651541054364",
  ZENDESK_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651494571676",
  TALENTHR_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651495097628",
  SAGE_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651511920156",
  WORDPRESS_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651534811036",
  ZAPIER_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651494601116",
  SALESFORCE_EMBEDDED_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651494734108",
  SALESFORCE_DATA_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651557042972",
  SHOPIFY_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651494413084",
  SHOPIFY_ZAPIER_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651534974620",
  SLACK_ZAPIER_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651556775580",
  PAYPAL_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651769057436",
  STRIPE_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651801532188",
  SHARE_CERTIFICATIONS_ON_LINKEDIN_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651322415900",
  RESET_BOOKMARKS_TOOLTIP_URL: "https://help.talentlms.com/hc/en-us/articles/9651383888156",
  REMAINING_LICENSES_TOOLTIP_URL: "https://help.talentlms.com/hc/en-us/articles/9651430665628",
  RESTRICT_REGISTRATION_TO_SPECIFIC_DOMAINS_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9652263428892-How-to-restrict-registration-to-specific-domains-in-TalentLMS",
  AUTOMATIONS_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651467215900",
  AUTOMATIONS_HOW_TO_WORK_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651467215900-How-to-work-with-automations",
  INTEGRATED_VIDEO_INTEGRATION_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651551520668",
  ZOOM_INTEGRATION_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651511786140",

  ZOOM_WEBINAR_INTEGRATION_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651511786140",
  GOTO_INTEGRATION_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651535345564",
  MSTEAMS_INTEGRATION_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651535404444",
  BIGBLUEBUTTON_INTEGRATION_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651556707228",
  CUSTOM_COURSE_FIELDS_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651414062876",
  CUSTOM_HOMEPAGE_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651454298780",
  CUSTOM_CERTIFICATES_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651316373148",
  CUSTOM_DOMAIN_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9652249770780",
  PRODUCT_COMMUNICATIONS_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/14104680388252-How-to-allow-product-communications-in-TalentLMS-Beta",
  TALENTLMS_BRANDING_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651427166492",
  ECOMMERCE_COUPONS_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651763696668",
  ECOMMERCE_CREDITS_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651763633180",
  ECOMMERCE_INVOICES_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651775467676",
  ECOMMERCE_DISCOUNTS_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651785371292",
  ECOMMERCE_SUBSCRIPTION_FREEMONTH_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651815541788",
  ECOMMERCE_SUBSCRIPTION_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651815458844",
  BRANCH_UPSELL_PAGE_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/10730422769436",
  BRANCH_GROUPS_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/10730406045980-How-to-use-groups-in-branches",
  BRANCH_LANGUAGE_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/10730375004828-Why-can-t-users-see-the-branch-in-their-preferred-language-in-TalentLMS-Beta",
  BRANCH_SUBSCRIPTIONS_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651815458844-How-to-work-with-subscriptions",
  BRANCH_ACTIVATE_CREDITS_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/9651763633180/",
  BRANCH_BADGE_SET_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/9651488086300",
  BRANCH_USER_TYPE_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/articles/10730422502300",
  SSO_DOCUMENTATION: "https://help.talentlms.com/hc/en-us/sections/9593886368668",
  WHITE_LABEL_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/14846610620572-How-can-I-hide-the-TalentLMS-branding",
  CMS_CLONE_DOCUMENTATION:
    "https://help.talentlms.com/hc/en-us/articles/14937077188124-How-to-clone-your-Homepage-from-the-Legacy-interface",
};

export const TALENT_LMS_MARKET_LINKS = {
  API_DOC_PDF: "/pages/docs/TalentLMS-API-Documentation.pdf",
  XAPI_DOC_PDF: "pages/docs/TalentLMS-TinCanAPI-Documentation.pdf",
  API_AND_API_PHP_LIBRARY: "/pages/docs/TalentLMS-API.zip",
};

export const GENERAL_LINK = {
  TALENT_LMS: "https://www.talentlms.com/",
  TERMS_SANCTIONS:
    "https://www.talentlms.com/terms#:~:text=20.%20SANCTIONS%20%26%20EXPORT%20CONTROLS",
  TALENTLMS_PRIVACY: "https://www.talentlms.com/privacy",
};
