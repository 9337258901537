import { Button, Text, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { tableHeaderContainer } from "./styles";
import { convertToDataTestIdName } from "@utils/helpers";

type TableHeaderProps = {
  title: string;
  backLink: string;
  secondaryActions?: JSX.Element;
  hideBackButton?: boolean;
  tooltipIcon?: {
    icon: JSX.Element;
    tooltipText: string;
  };
  className?: string;
};

const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    title,
    backLink,
    secondaryActions,
    tooltipIcon,
    hideBackButton = false,
    className,
  } = props;

  return (
    <header css={tableHeaderContainer} className={className ?? ""}>
      <div className="title-wrapper" data-testid={`${convertToDataTestIdName(title)}-title`}>
        {!hideBackButton && (
          <Button color="secondary" variant="link" as={Link} to={backLink} noGutters>
            <ArrowLeftSVG height={28} />
          </Button>
        )}
        <Text fontSize="2xl" weight="700">
          {title}
        </Text>
        {tooltipIcon && <Tooltip content={tooltipIcon.tooltipText}>{tooltipIcon.icon}</Tooltip>}
      </div>

      {secondaryActions && <div className="secondary-actions-wrapper">{secondaryActions}</div>}
    </header>
  );
};

export default TableHeader;
