import { t } from "@utils/i18n";
import { Pagination as IPagination } from "types/responses/index";
import { Column, TableProps } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";

export enum CourseUsersFilters {
  group = "[group_id]",
  branch = "[branch_id]",
}

export const PAGINATION: IPagination = { number: 1, size: 10 };
const SORTING: TableProps["sorting"] = { column: "formatted_name", isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};

export const courseUsersWordings = {
  unenroll: {
    progressHeader: t("courses.massActions.unenrollModalTitle"),
    progressMessage: "courses.massActions.unenrollProgress",
    confirmationHeader: "courses.massActions.unenrollModalTitle",
    confirmationButton: t("general.unenroll"),
    successMessage: "courses.massActions.unenrollSuccess",
    confirmationBodyTitle: "courses.massActions.unenrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  reset: {
    progressHeader: "courses.massActions.resetModalTitle",
    progressMessage: "courses.massActions.resetProgress",
    confirmationHeader: "courses.massActions.resetModalTitle",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationBodyTitle: "courses.massActions.resetWarning",
    successMessage: "courses.massActions.resetSuccess",
  },
  update_certificates: {
    progressHeader: "users.courses.massActionProgress",
    progressMessage: "users.courses.updatingCertificates",
    confirmationHeader: "users.courses.updateCertificate",
    confirmationButton: "general.update",
    successMessage: "users.courses.massUpdateCertificatesSuccess",
    confirmationBodyTitle: "users.courses.massUpdateCertificateWithWarning",
    confirmationBodyText: "users.courses.certificatesAffected",
  },
};

export const getCourseUsersInitialColumns = (): Column[] => {
  return [
    {
      accessor: "formatted_name",
      cell: t("messages.recipient.users_one"),
      classNames: ["formatted_name"],
      headerWidth: 250,
    },
    {
      accessor: "role_in_course",
      cell: t("general.role"),
      sortOrder: "desc",
      classNames: ["role_in_course"],
    },
    {
      accessor: "progress_status",
      cell: t("general.progressStatus"),
      classNames: ["progress_status"],
      sortOrder: "desc",
    },
    {
      accessor: "enrolled",
      cell: t("general.enrollmentDate"),
      sortOrder: "desc",
      classNames: ["enrolled"],
    },
    {
      accessor: "completion_date",
      cell: t("general.completionDate"),
      classNames: ["completion_date"],
      sortOrder: "desc",
    },
    {
      accessor: "expiration_date",
      cell: t("general.expirationDate"),
      classNames: ["expiration_date"],
      sortOrder: "desc",
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};
