import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

type ToolbarProps = {
  toolbarEl: Element;
  children: ReactNode;
};

const Toolbar: FC<ToolbarProps> = ({ toolbarEl, children }) => createPortal(children, toolbarEl);

export default Toolbar;
