import React, { FC } from "react";
import { contentStyles } from "./styles";

const Content: FC = ({ children }) => (
  <div css={contentStyles}>
    <div className="content-container">{children}</div>
  </div>
);

export default Content;
