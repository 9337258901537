import React, { FC, useState } from "react";
import { useResponsive } from "ahooks";
import { Button, Card, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";
import { FileModal } from "@components";
import { cardHeader, cardWrapper } from "./styles";
import { CourseFile, File } from "types/entities";
import { downloadFile, readableBytes, removeFileExtension } from "@utils/helpers";
import FileThumbnail from "./components/FileThumbnail";
import FileHover from "./components/FileHover";
import { SerializedStyles } from "@emotion/react";
import FileBody from "./components/FileBody";

const { Header, Body } = Card;

export type FileCardProps = (File | CourseFile) & {
  isReadonly?: boolean;
  isSelectable?: boolean;
  showExtension?: boolean;
  options?: DropdownItem[];
  onSelect?: () => void;
  onOptionSelect?: (option: DropdownItem) => void;
};

const FileCard: FC<FileCardProps> = (props) => {
  const { sm, md } = useResponsive();

  const {
    isReadonly = false,
    isSelectable = false,
    showExtension = false,
    options = [],
    onSelect,
    onOptionSelect,
    ...rest
  } = props;

  const { id, url, name, type, size_in_bytes, tags = [], shared, uploaded_at, preview_url } = rest;
  const showHover = sm && !isReadonly && !isSelectable;
  const size = readableBytes(size_in_bytes);
  const fileName = showExtension ? name : removeFileExtension(name);
  const isPreviewable = Boolean(preview_url) && md;

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const handleClick = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();

    if (isReadonly) return;

    if (isSelectable) {
      onSelect && onSelect();
      return;
    }

    if (isPreviewable) {
      setIsPreviewModalOpen(true);
      return;
    }

    await downloadFile({ fileUrl: url, fileName: name, fileId: id });
  };

  const closePreviewModal = (): void => {
    setIsPreviewModalOpen(false);
  };

  const handleOptionSelect = (option: DropdownItem): void => {
    onOptionSelect && onOptionSelect(option);
  };

  return (
    <div css={cardWrapper}>
      <Card className="card-container" onClick={handleClick}>
        <Header>
          <div css={(theme): SerializedStyles => cardHeader(theme, { isReadonly })}>
            <FileThumbnail fileType={type} fileUrl={url} fileName={fileName} />
            {showHover && (
              <FileHover isPreviewable={isPreviewable} size={size} shared={shared} tags={tags} />
            )}
          </div>
        </Header>

        <Body>
          <FileBody
            isReadonly={isReadonly}
            fileName={fileName}
            type={type}
            size={size}
            uploaded_at={uploaded_at}
          >
            {options.length > 0 && (
              <Dropdown list={options} onListItemSelect={handleOptionSelect}>
                <Button variant="ghost" noGutters as="div">
                  <VerticalEllipsisSVG height={16} />
                </Button>
              </Dropdown>
            )}
          </FileBody>
        </Body>
      </Card>

      {preview_url && (
        <FileModal {...rest} isOpen={isPreviewModalOpen} onClose={closePreviewModal} />
      )}
    </div>
  );
};

export default FileCard;
