import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { InputError, Heading } from "@epignosis_llc/gnosis";
import { container, title, contentContainer } from "./styles";
import WidgetErrorMsg from "./WidgetError";

export const Header: FC = ({ children }) => {
  return (
    <div css={title}>
      <Heading>{children}</Heading>
    </div>
  );
};

export const Content: FC = ({ children }) => {
  return <div css={contentContainer}>{children}</div>;
};

type WidgetProps = React.HTMLAttributes<HTMLElement> & {
  isLoading: boolean;
  error: unknown;
  background?: "primary" | "secondary";
  loader?: JSX.Element;
  showBorder?: boolean;
};

type WidgetCompoundProps = {
  Header: FC;
  Content: FC;
};

const Widget: FC<WidgetProps> & WidgetCompoundProps = ({
  isLoading,
  error,
  background = "primary",
  children,
  loader,
  showBorder,
  ...rest
}) => {
  let content;

  if (isLoading) {
    content = loader;
  }

  if (error) {
    content = (
      <InputError>
        <WidgetErrorMsg />
      </InputError>
    );
  }

  if (!isLoading && !error) {
    content = children;
  }

  return (
    <div
      css={(theme): SerializedStyles => container(theme, { background }, { showBorder })}
      {...rest}
    >
      {content}
    </div>
  );
};

Widget.Header = Header;
Widget.Content = Content;

export default Widget;
