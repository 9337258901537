// Packages or third-party libraries
import React, { FC, useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { DropdownItem, Row, TableHandlers } from "@epignosis_llc/gnosis";

// Components
import { CustomTableActions, MassActionGradingDrawer, ResetModal } from "@components";
import { buildEmptyStateProps, emptyState } from "@components/CustomTable";
import { MassActionParam, MassActionType } from "@components/ReusableComponents";
import { SendMessageMassActionDrawer } from "@views/Users";
import SubmissionsTable from "./components/SubmissionsTable";
import GradingDrawer from "./components/GradingDrawer";

// Utils, hooks
import {
  applyQueryFilter,
  buildPaginatedSearchQuery,
  downloadFile,
  generalNotification,
  handleTableState,
  mapTableToSelectSorting,
  getMassActionsOptions,
} from "@utils/helpers";
import { usePaginatedStateReducer, useApplyTranslations } from "@hooks";
import { PaginatedState, PaginatedStateActions } from "@hooks/usePaginatedStateReducer";
import { handleAssignmentErrors } from "@errors/assignmentErrors";

// Other imports
import {
  resetSubmission,
  submissionsMassActions,
  submissionsMassActionsCount,
} from "@api/gradingHub";
import { getSubmissions, getUnitSubmissions } from "@api/submissions";
import { MassActionsProps } from "@components/CustomTable/types";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";
import { SubmissionListing } from "types/entities";
import { ResetSubmissionArgs } from "./types";
import queryKeys from "@constants/queryKeys";
import { DEFAULT_FILTERS, DEFAULT_STATE, getAssignmentsFilterOptions } from "./constants";
import { DrawerSize } from "types/common";

type SubmissionsProps = {
  courseId?: string;
  unitId?: string;
  drawerSize?: DrawerSize;
  hasBackButton?: boolean;
  hideColumns?: boolean;
};

const Submissions: FC<SubmissionsProps> = ({
  courseId,
  unitId,
  drawerSize = "md",
  hasBackButton = false,
  hideColumns = false,
}) => {
  const { t } = useApplyTranslations();
  const defaultState = handleTableState(DEFAULT_STATE);
  const queryClient = useQueryClient();

  const [submissionIds, setSubmissionIds] = useState<number[]>([]);
  const [resetInput, setResetInput] = useState(false);
  const [submissionsState, submissionsDispatch] = usePaginatedStateReducer(defaultState);
  const [selectedSubmission, setSelectedSubmission] = useState<SubmissionListing | null>(null);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [isGradingDrawerOpen, setGradingDrawerOpen] = useState(false);
  const [isMessagingDrawerOpen, setMessagingDrawerOpen] = useState(false);
  const [isMassActionGradingDrawerOpen, setMassActionGradingDrawerOpen] = useState(false);

  const [tabIndex, setTabIndex] = useState(1);
  const tableRef = React.useRef<TableHandlers>(null);

  const { pagination, sorting: tableSorting, filters } = submissionsState;
  const sorting = tableSorting?.column ? [mapTableToSelectSorting(tableSorting)] : [];
  const searchQuery = buildPaginatedSearchQuery({ pagination, sorting, filters });

  const handleRowSelect = (rows: Row[]): void => {
    setSubmissionIds(rows.map((course) => Number(course.id)));
  };

  const handleSortingChanged = (sorting: PaginatedState["sorting"]): void => {
    submissionsDispatch({ type: PaginatedStateActions.sorting, payload: { sorting } });
  };

  const handlePaginationPageChange = (value: number): void => {
    submissionsDispatch({ type: PaginatedStateActions.paginationPage, payload: { number: value } });
  };

  const handlePaginationPageSizeChange = (value: number): void => {
    submissionsDispatch({
      type: PaginatedStateActions.paginationPageSize,
      payload: { size: value },
    });
  };

  const handleSearchChanged = (searchValue: string): void => {
    setResetInput(false);
    const filter = { key: "[keyword][like]", value: searchValue };
    const newFilters = applyQueryFilter({ filters, filter });

    submissionsDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFiltersChanged = ({ category, value }: DropdownItem): void => {
    const filter = { key: category as string, value: value as string };
    const newFilters = applyQueryFilter({ filters: [...filters], filter });
    submissionsDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFilterRemove = ({ category, value }: DropdownItem): void => {
    const newFilters = filters.filter((item) => !(category === item.key && value === item.value));
    submissionsDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleRestoreDefault = (): void => {
    submissionsDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: DEFAULT_FILTERS },
    });
    setResetInput(true);
  };

  const onDownload = async (subId: string): Promise<void> => {
    const submission = submissions?.data.find(({ id }) => id?.toString() === subId.toString());

    if (submission) {
      const { url, name, id: fileId } = submission.submission_file;
      await downloadFile({ fileUrl: url, fileName: name, fileId });
    }
  };

  const onGrade = (subId: string, shouldOpenAssignemntTab?: boolean): void => {
    setTabIndex(shouldOpenAssignemntTab ? 1 : 2);
    const submission = submissions?.data.find(({ id }) => id?.toString() === subId.toString());

    if (submission) {
      setSelectedSubmission(submission);
      setGradingDrawerOpen(true);
    }
  };

  const onMessage = (subId: string): void => {
    const submission = submissions?.data.find(({ id }) => id?.toString() === subId.toString());

    if (submission) {
      setSelectedSubmission(submission);
      setMessagingDrawerOpen(true);
    }
  };

  const openResetModal = (subId: string): void => {
    const submission = submissions?.data.find(({ id }) => id.toString() === subId);

    if (submission) {
      setSelectedSubmission(submission);
      setResetModalOpen(true);
    }
  };

  const { mutate: resetMutation } = useMutation(
    [queryKeys.gradingHub.resetSubmission],
    (data: ResetSubmissionArgs) => resetSubmission(data.unitId, data.userId),
    {
      onSettled: () => {
        invalidateSubmissionTable();
      },
      onSuccess: () => {
        generalNotification("success", <p>{t("assignment.statusUpdatedSuccesfully")}</p>);
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
    },
  );

  const handleOnConfirmResetModal = (): void => {
    if (!selectedSubmission) return;

    const unitId = selectedSubmission.unit.id.toString();
    const userId = selectedSubmission.user.id.toString();

    resetMutation({ unitId, userId });
  };

  const closeResetModal = (): void => {
    setSelectedSubmission(null);
    setResetModalOpen(false);
  };

  const {
    status,
    data: submissions,
    error,
  } = useQuery(
    [queryKeys.gradingHub.getSubmissions, searchQuery],
    () => (unitId ? getUnitSubmissions(unitId, searchQuery) : getSubmissions(searchQuery)),
    {
      select: (submissions) => ({
        data: submissions._data,
        pagination: submissions._meta?.pagination,
      }),
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
      refetchOnWindowFocus: true,
    },
  );

  const tableStatus = { status, error };

  const emptyStateProps = buildEmptyStateProps({
    filters,
    tableType: "submissions",
    isDrawer: false,
    handleRestoreDefault,
  });

  const handleDrawerClose = (): void => {
    setGradingDrawerOpen(false);
    setMessagingDrawerOpen(false);
    setSelectedSubmission(null);
    setMassActionGradingDrawerOpen(false);
    cleanState();
  };

  const invalidateSubmissionTable = (): void => {
    queryClient.invalidateQueries([queryKeys.gradingHub.getSubmissions, searchQuery]);
    queryClient.invalidateQueries([queryKeys.gradingHub.pendingCount]);

    // we need to invalidate the units because of the number indicator in the sidebar
    queryClient.invalidateQueries([queryKeys.units, courseId, false]);
  };

  // Mass actions

  const countRequest = useCallback(
    (type: MassActionType, data: MassActionParam): Promise<CountMassActionResponse> => {
      return submissionsMassActionsCount(type, { unit_progress_ids: submissionIds, ...data });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(submissionIds)],
  );

  const massActionRequest = useCallback(
    (type: MassActionType, data: MassActionParam): Promise<MassActionResultResponse> => {
      return submissionsMassActions(type, { unit_progress_ids: submissionIds, ...data });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(submissionIds)],
  );

  const cleanState = (): void => {
    setSubmissionIds([]);
    tableRef.current?.resetSelected();
  };

  const toggleGradeMassActionDrawer = (): void => {
    setMassActionGradingDrawerOpen((v) => !v);
  };

  const massActionsProps: MassActionsProps = {
    allowMassActions: true,
    massActionsOptions: getMassActionsOptions(),
    originTableName: "assignments",
    countRequest,
    massActionRequest,
    cleanState,
    handleInvalidateQueryMassActions: invalidateSubmissionTable,
    toggleMassActionDrawer: (): void => setMessagingDrawerOpen((v) => !v),
    toggleGradeMassActionDrawer,
    selectedRows: submissionIds.length,
  };

  const selectedUsers = submissions
    ? submissions.data
        .filter((submission) => submissionIds.includes(submission.id))
        .map((submission) => submission.user)
    : [];

  const uniqueUserIds = [...new Set(selectedUsers.map((user) => user.id))];

  return (
    <>
      <CustomTableActions
        filterDropdownOptions={getAssignmentsFilterOptions()}
        searchValue={defaultState.searchValue}
        tableHasData={Boolean(submissions?.data.length)}
        selectedFilters={filters}
        resetInput={resetInput}
        onSearchChange={handleSearchChanged}
        onFilterSelect={handleFiltersChanged}
        onFilterRemove={handleFilterRemove}
        areFiltersCategorized={false}
        massActionsProps={massActionsProps}
      />

      <SubmissionsTable
        data={submissions?.data ?? []}
        emptyState={emptyState(emptyStateProps)}
        paginationRes={submissions?.pagination}
        state={submissionsState}
        tableStatus={tableStatus}
        onSortingChanged={handleSortingChanged}
        onPageChange={handlePaginationPageChange}
        onPageSizeChange={handlePaginationPageSizeChange}
        onRowSelect={handleRowSelect}
        onDownload={onDownload}
        onGrade={onGrade}
        onMessage={onMessage}
        onReset={openResetModal}
        tableRef={tableRef}
        hideColumns={hideColumns}
      />

      <ResetModal
        type="assignment"
        selectedSession={selectedSubmission}
        isResetModalOpen={isResetModalOpen}
        handleOnConfirmResetModal={handleOnConfirmResetModal}
        closeResetModal={closeResetModal}
      />

      {isGradingDrawerOpen && selectedSubmission && (
        <GradingDrawer
          key={selectedSubmission.id}
          tabIndex={tabIndex}
          listingSubmission={selectedSubmission}
          isDrawerOpen={isGradingDrawerOpen}
          size={drawerSize}
          hasBackButton={hasBackButton}
          onCloseDrawer={handleDrawerClose}
          invalidateSubmissionTable={invalidateSubmissionTable}
          showAssignmentTab={!hideColumns}
        />
      )}

      {isMessagingDrawerOpen && (
        <SendMessageMassActionDrawer
          isDrawerOpen={isMessagingDrawerOpen}
          userIds={selectedSubmission ? [selectedSubmission.user.id] : uniqueUserIds}
          onClose={handleDrawerClose}
          size={drawerSize}
          hasBackButton={hasBackButton}
        />
      )}

      {isMassActionGradingDrawerOpen && (
        <MassActionGradingDrawer
          isDrawerOpen={isMassActionGradingDrawerOpen}
          title={t("users.massActions.massGradeUsers")}
          size={drawerSize}
          hasBackButton={hasBackButton}
          countRequest={countRequest}
          massActionRequest={massActionRequest}
          onCloseDrawer={handleDrawerClose}
          invalidateSubmissionTable={invalidateSubmissionTable}
          cleanState={cleanState}
        />
      )}
    </>
  );
};

export default Submissions;
