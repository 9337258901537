import React, { FC } from "react";
import { CourseEditLayoutStyles } from "./styles";
import Sidebar, { SidebarProps } from "./Sidebar";
import Main, { MainProps } from "./Main";
import Content, { ContentProps } from "./Content";
import Footer from "./Footer";

type CourseEditLayoutProps = {
  Sidebar: SidebarProps;
  Main: MainProps;
  Content: ContentProps;
  Footer: FC;
};

const CourseEditLayout: FC & CourseEditLayoutProps = ({ children }) => (
  <div css={CourseEditLayoutStyles}>{children}</div>
);

CourseEditLayout.Sidebar = Sidebar;
CourseEditLayout.Main = Main;
CourseEditLayout.Content = Content;
CourseEditLayout.Footer = Footer;

export default CourseEditLayout;
