// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Checkbox } from "@epignosis_llc/gnosis";
import { GripLinesSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { reorderColumns } from "./styles";

// Utils
import { handleReorder } from "./helpers";

// Types
import { CheckboxOption, ReorderColumnsProps } from "./types";

const ReorderColumns: FC<ReorderColumnsProps> = ({
  columnsToOrder,
  setColumns,
  handleCheckboxChange,
}) => {
  const [listItems, setListItems] = useState<CheckboxOption[]>(columnsToOrder);

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) return;

    const items = handleReorder(listItems, result.source.index, result.destination.index);

    setListItems(items);
    setColumns(items);
  };

  useEffect(() => {
    setListItems(columnsToOrder);
  }, [columnsToOrder]);

  return (
    <section css={reorderColumns}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided): JSX.Element => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {listItems.map(({ name, label, disabled, checked }, index) => (
                <Draggable key={name} draggableId={name as string} index={index}>
                  {(provided): JSX.Element => (
                    <div
                      className="draggable-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <GripLinesSVG height={16} className="grip-icon" />

                      <Checkbox
                        id={name}
                        key={name}
                        value={name}
                        label={label}
                        name={name}
                        disabled={disabled}
                        checked={checked}
                        onChange={(e): void => handleCheckboxChange(e.target.checked, name)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default ReorderColumns;
