import React, { ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { FeatureLockSVG } from "@epignosis_llc/gnosis/icons";
import { settingCard } from "./styles";

type SettingCardProps = {
  title?: string;
  placement?: string;
  lockedFeature?: boolean;
  description?: ReactNode;
  hasErrors?: boolean;
  children?: ReactNode;
  withSpaceLeft?: boolean;
  testId?: string;
  lockedFeatureTooltip?: ReactNode | string;
};

const LockedFeatureIcon = ({ tooltip }: { tooltip?: ReactNode | string }): JSX.Element =>
  tooltip ? (
    <Tooltip content={tooltip}>
      <div className="settings-tooltip">
        <FeatureLockSVG height={16} />
      </div>
    </Tooltip>
  ) : (
    <div className="settings-tooltip">
      <FeatureLockSVG height={16} />
    </div>
  );

const SettingCard = ({
  title,
  description,
  hasErrors = false,
  placement = "center",
  lockedFeature = false,
  lockedFeatureTooltip,
  children,
  withSpaceLeft,
  testId,
}: SettingCardProps): JSX.Element => {
  const placementPosition = hasErrors ? "start" : placement;
  return (
    <div css={(theme): SerializedStyles => settingCard(theme, { placementPosition })}>
      <div className="settings-content">
        {title && (
          <Heading as="h4" size="sm" className="settings-heading">
            {title}
            {lockedFeature && <LockedFeatureIcon tooltip={lockedFeatureTooltip} />}
          </Heading>
        )}
        {description &&
          (typeof description === "string" ? (
            <Text
              as="div"
              fontSize="sm"
              className="settings-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <div className="settings-description">{description}</div>
          ))}
      </div>
      <div className="setting-actions" data-testid={testId}>
        <div className={classNames({ "space-left": withSpaceLeft })}>{children}</div>
      </div>
    </div>
  );
};

export default SettingCard;
