import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { TrashAltSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type DeleteActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const DeleteAction: FC<DeleteActionProps> = ({
  tooltipContent,
  icon: Icon = TrashAltSVG,
  iconHeight = 16,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.delete")}
      onClick={onClick}
      data-testid="delete-action"
      {...rest}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default DeleteAction;
