/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactChildren, ReactNode } from "react";
import { QueryStatus } from "react-query";
import { Loader } from "@epignosis_llc/gnosis";
import { ErrorResult } from "@components/ReusableComponents";
import { useUIStore } from "@stores";
export type SkeletonLoaderProps = {
  status: QueryStatus;
  error: unknown | null;
  children: Exclude<null | undefined | any, ReactChildren>;
  loader?: ReactNode;
  fullScreen?: boolean;
};
const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  status,
  error,
  children,
  fullScreen = false,
  loader = <Loader fullScreen={fullScreen} />,
}) => {
  const showSessionExpirationModal = useUIStore((state) => state.showSessionExpirationModal);
  const isLoading = status === "loading";
  if (isLoading) return loader;
  // not show error when expiration modal is opened
  if (showSessionExpirationModal) return children ? children : null;
  if (error) return <ErrorResult />;
  return children;
};
export default SkeletonLoader;
