// Packages or third-party libraries
import React, { FC } from "react";
import { Modal, Select, Text } from "@epignosis_llc/gnosis";

// Utils
import { t } from "@utils/i18n";

// Other imports
import { SelectOption } from "types/common";
import { SMART_TAG_INSERT, SMART_TAG_CLOSE } from "./constants";

type SmartTagsModalProps = {
  isOpen: boolean;
  options: SelectOption[];
  onClose: () => void;
};

const SmartTagsModal: FC<SmartTagsModalProps> = ({ isOpen, options, onClose }) => {
  const handleChange = (smartTag: string): void => {
    PubSub.publish(SMART_TAG_INSERT, smartTag);
    onClose();
  };

  const handleClose = (): void => {
    PubSub.publish(SMART_TAG_CLOSE);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header>
        <Text fontSize="xl" weight="700">
          {t("general.smartTags")}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Select
          options={options}
          maxWidth="100%"
          onChange={(option): void => handleChange((option as SelectOption).value)}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SmartTagsModal;
