import React, { FC, ReactChild } from "react";
import { m } from "framer-motion";
import { labelOnHoverWrapper } from "./styles";

export type LabelOnHoverWrapperProps = {
  icon: ReactChild;
  label: string | ReactChild;
};

const LabelOnHoverWrapper: FC<LabelOnHoverWrapperProps> = ({ icon, label, children }) => (
  <div css={labelOnHoverWrapper}>
    <m.div initial="hidden" whileHover="show" className="container">
      <m.span id="icon">{icon}</m.span>
      <m.span id="label">{label}</m.span>
    </m.div>
    {children}
  </div>
);

export default LabelOnHoverWrapper;
