import { Heading, Text } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { pageHeader } from "./style";

export type PageHeaderProps = {
  title: string;
  subtitle?: string;
};

const PageHeader: FC<PageHeaderProps> = ({ title, subtitle, children }) => {
  return (
    <div css={pageHeader}>
      <div className="pageHeader-container">
        <div className="left-side">
          <Heading className="title" size="2xl">
            {title}
          </Heading>
          <Text className="subtitle" fontSize="sm">
            {subtitle}
          </Text>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default PageHeader;
