import React, { FC, MouseEvent } from "react";
import { Button, MediaPlayer, Tooltip, Text, Result, Loader } from "@epignosis_llc/gnosis";
import { VideoSVG, AudioSVG, CircleFilledSVG, SquareFilledSVG } from "@epignosis_llc/gnosis/icons";
import { ReactMediaRecorderRenderProps } from "react-media-recorder";
import { isChromeBrowser, secondsToTime } from "@utils/helpers";
import { RecordingPreviewStyles } from "./styles";
import { Config } from "react-player";
import { useApplyTranslations } from "@hooks";

const videoPreviewConfig: Config = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
    },
  },
};

type RecordingPreviewProps = ReactMediaRecorderRenderProps & {
  duration: number;
  fixedURL: string;
  canSubmit: boolean;
  recordingType: "video" | "screen" | "audio";
  isSubmitLoading?: boolean;
  handleStartRecording: (e: MouseEvent<SVGSVGElement | HTMLButtonElement>) => void;
  handleStopRecording: (e: MouseEvent<SVGSVGElement | HTMLButtonElement>) => void;
  onSubmit: () => void;
  onCancel?: () => void;
};

const RecordingPreview: FC<RecordingPreviewProps> = ({
  status,
  error,
  mediaBlobUrl,
  previewStream,
  duration,
  fixedURL,
  recordingType,
  canSubmit,
  isSubmitLoading = false,
  handleStartRecording,
  handleStopRecording,
  onSubmit,
  onCancel,
}) => {
  const { t } = useApplyTranslations();
  const isChrome = isChromeBrowser();
  const isLoading = status === "acquiring_media";
  const showPreviewStream = ["recording", "idle"].includes(status) && !error && !isLoading;
  const showVideoPreview =
    Boolean(mediaBlobUrl) && !["recording", "idle"].includes(status) && !error && !isLoading;
  const isRecording = status === "recording";
  const isAudio = recordingType === "audio";
  const mediaType = isAudio ? "audio" : "video";

  return (
    <div css={RecordingPreviewStyles}>
      {error ? (
        <Result title={t("recording.error")} className={`recording-error ${recordingType}`} />
      ) : (
        <>
          {/* Require space to append correctly classname in Loader component */}
          {/* TODO: fix in Gnosis */}
          {isLoading && <Loader className={` recording-loader ${recordingType}`} />}

          {showPreviewStream && (
            <>
              <div className={`preview-stream-container ${recordingType}`}>
                {isAudio ? (
                  <div className="audio-recording">
                    <AudioSVG height={64} />
                  </div>
                ) : (
                  <MediaPlayer
                    id={`preview-stream-${recordingType}`}
                    className="react-player"
                    type="video"
                    src={previewStream as MediaStream}
                    playing
                    controls={false}
                    width="100%"
                    height="100%"
                  />
                )}

                <div className="actions-container">
                  <div className="actions">
                    {!isRecording ? (
                      <Tooltip content={t("recording.start")} as="span">
                        <CircleFilledSVG
                          height={16}
                          onClick={handleStartRecording}
                          className="action-item"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip content={t("recording.stop")} as="span">
                        <SquareFilledSVG
                          height={16}
                          onClick={handleStopRecording}
                          className="action-item"
                        />
                      </Tooltip>
                    )}

                    {isRecording && Boolean(duration) && (
                      <div className="recording-duration">
                        {isAudio ? <AudioSVG height={32} /> : <VideoSVG height={24} />}
                        <Text fontSize="sm">{secondsToTime(duration, true)}</Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {onCancel && (
                <div className="preview-cancel-container">
                  <Button type="reset" color="secondary" onClick={onCancel}>
                    {t("general.cancel")}
                  </Button>
                </div>
              )}
            </>
          )}

          {showVideoPreview && (
            <div className={`recording-preview-container ${recordingType}`}>
              <MediaPlayer
                id={`preview-recording-${recordingType}`}
                className="react-player"
                type={mediaType}
                src={isChrome ? fixedURL : mediaBlobUrl}
                controls
                config={videoPreviewConfig}
                width="100%"
                height={isAudio ? "63px" : "100%"}
              />

              <div className="actions">
                <Button aria-label="Record again" variant="outline" onClick={handleStartRecording}>
                  {t("recording.again")}
                </Button>

                <div className="actions-end">
                  <Button
                    aria-label="Submit reconding"
                    disabled={!canSubmit}
                    isLoading={isSubmitLoading}
                    onClick={onSubmit}
                  >
                    {t("general.submit")}
                  </Button>

                  {onCancel && (
                    <Button type="reset" color="secondary" onClick={onCancel}>
                      {t("general.cancel")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RecordingPreview;
