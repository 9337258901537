import React, { FC } from "react";
import { m, Variants } from "framer-motion";
import { actionButtonContainer } from "./styles";
import { IconType } from "types/common";

export type ActionButtonProps = {
  icon: IconType;
  label: string;
  onClick: () => Promise<void> | void;
  children?: never;
};

const labelVariants: Variants = {
  hidden: {
    display: "none",
    opacity: 0,
  },
  show: {
    display: "inline-block",
    opacity: 1,
  },
};

const ActionButton: FC<ActionButtonProps> = ({ icon: Icon, label, onClick }) => (
  <m.div css={actionButtonContainer} initial="hidden" whileHover="show" whileFocus="show">
    {label.length > 0 && (
      <m.span id="actionLabel" className="label" variants={labelVariants}>
        {label}
      </m.span>
    )}
    <m.button onClick={onClick} aria-labelledby="actionLabel" className="action-icon">
      <Icon height={22} />
    </m.button>
  </m.div>
);

export default ActionButton;
