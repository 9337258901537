import React, { FC, ReactElement } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { dateFormatsMapping } from "@utils/helpers/date-time";
import { Checkbox, Grid, Input, InputError, Select } from "@epignosis_llc/gnosis";
import { DateInput, TextWithIcon } from "@components";
import { format } from "date-fns";
import { SelectOption } from "types/common";
import { useConfigurationStore } from "@stores";
import { EditCourseData } from "@views/CourseEdit/api";
import { CourseCustomFieldWithValue } from "../DetailsTab";
import { SmartTagSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";
import { courseOptionsIds } from "../../constants";

type CustomFieldsOptionsProps = {
  form: UseFormReturn<EditCourseData>;
  customFields: CourseCustomFieldWithValue[] | undefined;
};

const CustomFieldsOptions: FC<CustomFieldsOptionsProps> = ({ customFields, form }) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const {
    control,
    formState: { errors },
  } = form;

  const renderCustomFields = (): JSX.Element => {
    return (
      <>
        {customFields?.map((item) => {
          return (
            <Grid.Item key={item.id} colSpan={1}>
              <Controller
                name={`custom_fields.${item.name}`}
                control={control}
                render={({ field: { onChange, value } }): ReactElement => {
                  switch (item.type) {
                    case "checkbox":
                      return (
                        <Checkbox
                          id={item.id.toString()}
                          value={item.name}
                          name={item.name}
                          required={item.mandatory}
                          label={item.name}
                          defaultChecked={item.value ? item.value === "on" : Boolean(item.checked)}
                          onChange={(value): void => {
                            onChange(value.target.checked ? "on" : "off");
                          }}
                        />
                      );
                    case "date": {
                      return (
                        <>
                          <DateInput
                            className="date-picker"
                            id={item.id.toString()}
                            required={item.mandatory}
                            label={item.name}
                            value={value ? new Date(value) : null}
                            onChange={(value): void => {
                              // set the form date to have the same format with the API response
                              onChange(value ? format(value, "yyyy-MM-dd") : "");
                            }}
                            status={errors?.custom_fields?.[item.name] ? "error" : "valid"}
                            dateFormat={
                              domainSettings && dateFormatsMapping[domainSettings.date_format]
                            }
                          />
                          {errors?.custom_fields?.[item.name] && (
                            <InputError>{errors.custom_fields[item.name]?.message}</InputError>
                          )}
                        </>
                      );
                    }
                    case "dropdown": {
                      const selectOptions =
                        item?.dropdown_items?.map(({ item }) => ({
                          value: item,
                          label: item,
                        })) ?? [];

                      const defaultValue = item.dropdown_items?.find(
                        (option) => option.item === item.value,
                      )?.item;

                      const defaultOption = defaultValue
                        ? { label: defaultValue, value: defaultValue }
                        : undefined;

                      return (
                        <>
                          <Select
                            maxWidth="100rem"
                            id={item.id.toString()}
                            required={item.mandatory}
                            label={item.name}
                            placeholder="-"
                            defaultValue={defaultOption}
                            status={errors?.custom_fields?.[item.name] ? "error" : "valid"}
                            options={selectOptions}
                            isClearable={!item.mandatory}
                            hasInnerSearch={true}
                            onChange={(option): void => {
                              const { value } = (option as SelectOption) ?? {};
                              onChange(value ?? "");
                            }}
                          />

                          {errors?.custom_fields?.[item.name] && (
                            <InputError>{errors.custom_fields[item.name]?.message}</InputError>
                          )}
                        </>
                      );
                    }

                    default:
                      return (
                        <>
                          <Input
                            id={item.id.toString()}
                            required={item.mandatory}
                            label={item.name}
                            value={value ?? ""}
                            onChange={onChange}
                            data-lpignore="true"
                            status={errors?.custom_fields?.[item.name] ? "error" : "valid"}
                          />

                          {errors?.custom_fields?.[item.name] && (
                            <InputError>{errors.custom_fields[item.name]?.message}</InputError>
                          )}
                        </>
                      );
                  }
                }}
              />
            </Grid.Item>
          );
        })}
      </>
    );
  };

  return (
    <div className="custom-fields-options-container" id={courseOptionsIds.customFieldsOptions}>
      <TextWithIcon icon={<SmartTagSVG height={32} />} label={t("courseEdit.customFields")} />
      <Grid templateColumns={1} rowGap={1} columnGap={1} className="grid-container">
        {renderCustomFields()}
      </Grid>
    </div>
  );
};

export default CustomFieldsOptions;
