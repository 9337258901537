import React, { FC, useEffect } from "react";
import { Button, Result, Table, TableHandlers, TableProps, Tooltip } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { PaginationProps, TableStatus } from "./types";
import { tableContainer } from "./styles";
import PaginationWithOptions from "@components/Pagination/PaginationWithOptions";
import Skeletons from "@components/Skeleton/Skeleton";
import useUIStore from "@stores/ui";
import { DownloadIconSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type CustomTableProps = {
  tableProps: TableProps;
  tableStatus?: TableStatus;
  paginationProps?: PaginationProps;
  headerElements?: JSX.Element;
  selectable?: boolean;
  exportText?: string;
  id?: string;
  disabled?: boolean;
  handleExportToCSV?: () => void;
  tableRef?: React.RefObject<TableHandlers>;
};

const CustomTable: FC<CustomTableProps> = ({
  tableProps,
  tableStatus,
  paginationProps,
  headerElements,
  exportText,
  id,
  disabled = false,
  handleExportToCSV,
  tableRef,
}) => {
  const { t } = useApplyTranslations();
  const { sm } = useResponsive();
  const { status = "success", error = null } = tableStatus ?? {};
  const { setShouldShowConfirmationModal } = useUIStore();

  // Remove don't ask again setting on unmount
  useEffect(() => {
    return () => {
      setShouldShowConfirmationModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={tableContainer} data-testid="custom-table-container">
      {headerElements && headerElements}

      <Skeletons.Loader
        status={status}
        error={error}
        loader={
          <div>
            {sm ? (
              <Skeletons.Table />
            ) : (
              Array.from({ length: 5 }, (_, index) => <Skeletons.ListItem key={index} />)
            )}
          </div>
        }
      >
        {tableProps.rows.length > 0 ? (
          <div>
            <Table {...tableProps} ref={tableRef} id={id} />

            <div className="table-footer-container">
              {handleExportToCSV && (
                <Tooltip content={exportText ?? t("general.saveAsCSV")}>
                  <Button
                    variant="ghost"
                    noGutters
                    onClick={handleExportToCSV}
                    className="export-to-csv-btn"
                    disabled={disabled}
                  >
                    <DownloadIconSVG height={32} />
                  </Button>
                </Tooltip>
              )}

              <div className="pagination-section">
                {paginationProps && paginationProps.paginationRes && (
                  <PaginationWithOptions {...paginationProps.paginationRes} {...paginationProps} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <Result {...tableProps.emptyState} />
        )}
      </Skeletons.Loader>
    </div>
  );
};

export default CustomTable;
