import { css } from "@emotion/react";

export const courseHeaderStyles = ({ courseOverview }, { isSticky }) => css`
  display: flex;
  gap: 2rem;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;

  .back-button {
    position: absolute;
    top: 1rem;
    inset-inline-start: 1rem;
  }

  .start-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    color: ${courseOverview.headerColor};
    min-height: 2.5rem;
    flex: 1;

    .additional-info-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .restrictions-wrapper {
        align-items: center;
        display: flex;
        gap: 1rem;
      }

      .course-acquired-tag {
        gap: 0.25rem;
        padding: 0.325rem 0.5rem;
      }
    }
  }

  .end-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    min-width: 400px;
    width: 400px;
    color: ${courseOverview.headerColor};
    min-height: 2.5rem;
    align-items: ${isSticky ? "end" : "normal"};

    .thumbnail-image {
      width: 100%;
      min-height: 275px;
      aspect-ratio: 3/2;
    }
  }

  .clickable-text {
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
  }
`;

export const titleContainerStyles = ({ courseEdit: { error } }) => css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .name-error {
    color: ${error.color};
    background-color: ${error.backgroundColor};
    width: fit-content;
    margin: 0.5rem 0 0;
  }

  .name {
    cursor: default;
  }
`;

export const courseCodeStyles = (theme, { canEditOptions }) => css`
  width: fit-content;
  cursor: ${canEditOptions ? "pointer" : "default"};

  &:hover {
    text-decoration: ${canEditOptions ? "underline" : "none"};
  }
`;

export const categoryTextStyles = (theme, { canEditOptions }) => css`
  width: fit-content;
  margin-bottom: 1rem;
  cursor: ${canEditOptions ? "pointer" : "default"};

  .category {
    word-break: break-word;
    font-style: italic;

    &:hover {
      text-decoration: ${canEditOptions ? "underline" : "none"};
    }
  }
`;

export const infoTagStyles = ({ button: { primaryLight } }, { shouldShowEdit }) => css`
  gap: 2px;
  color: inherit;
  width: fit-content;
  padding: 0 0.5rem 0 0;
  cursor: ${shouldShowEdit ? "pointer" : "default"};
  background-color: ${primaryLight.default.background};
  transition: background-color 0.2s ease-in;
  height: 2rem;

  &:hover {
    background-color: ${shouldShowEdit
      ? primaryLight.hover.background
      : primaryLight.default.background};
  }
`;

export const ratingContainer = ({ courseOverview }) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  margin-inline-start: -0.5rem;

  .average-rating {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .upper-dot::after {
    content: "";
    position: absolute;
    background-color: ${courseOverview.headerColor};
    width: 2px;
    height: 2px;
    border-radius: 50%;
  }

  .reset-btn {
    height: auto;

    &:hover {
      color: ${courseOverview.headerColor};
    }
  }
`;

export const introVideoModalBody = css`
  .intro-video {
    aspect-ratio: 3/2;
  }
`;

export const restrictionsStyles = ({ button: { primaryLight } }, { hasOneRestriction }) => css`
  height: 2rem;

  .restriction-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.2s ease-in;

    &.clickable-item {
      cursor: pointer;

      &:hover {
        text-decoration: underline;

        .restriction-item-icon {
          background-color: ${primaryLight.hover.background};
        }
      }
    }

    .restriction-item-icon {
      height: 2rem;
      width: 2rem;
      background-color: ${primaryLight.default.background};
      border-radius: 5px;
    }

    button {
      height: 2rem;
      min-width: 2rem;
      padding: 0;
      cursor: initial;
    }
  }

  .restrictions-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      display: ${hasOneRestriction ? "none" : "initial"};
      margin: 0;
    }
  }

  .dropdown-list-item.readonly {
    color: initial;
  }

  .text-container {
    display: flex;
    flex-direction: column;
  }
`;

export const startButtonStyles = ({ courseOverview }) => css`
  width: 100%;

  button.start-button {
    width: 100%;
    background-color: ${courseOverview.startButton.background};
    font-weight: 700;
    border-color: ${courseOverview.startButton.background};

    &:not(.disabled):hover {
      color: ${courseOverview.startButton.hoverColor};
      background-color: ${courseOverview.startButton.background};
    }

    &.read-only:hover {
      color: ${courseOverview.startButton.color};
    }

    &:hover {
      border-color: ${courseOverview.startButton.background};
    }

    &.read-only {
      cursor: initial;
    }

    .price {
      color: inherit;
    }
  }
`;

export const bundlesStyles = () => css`
  .dropdown-list-item svg {
    min-width: 2rem;
  }

  .clickable-text {
    cursor: pointer;
    font-weight: 700;
  }
`;
