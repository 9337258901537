import React, { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useAuth } from "@hooks";
import { URLS } from "@constants/urls";
import SignInModal from "@components/SignIn/SignInModal";
import { useConfigurationStore, useUIStore } from "@stores";
import TermsModal from "@views/Terms/TermsModal";
import localStorageKeys from "@constants/localStorageKeys";
import queryParameters from "@constants/queryParameters";

const PrivateRoute: FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { boot, hardShutdown } = useIntercom();
  const { showSessionExpirationModal, setShowSessionExpirationModal, setShowTermsModal } =
    useUIStore();
  const { domainTerms, userIntegrations, userProfileData } = useConfigurationStore();

  const { intercom_settings = null } = userIntegrations ?? {};
  const isPortalOwner = Boolean(userProfileData?.is_owner);

  // on initial render close sign in modal
  useEffect(() => {
    setShowSessionExpirationModal(false);
    localStorage.removeItem(localStorageKeys.PUBLIC_COURSE); // When the user logins or signs up, and is authenticated remove the public token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPortalOwner && intercom_settings) {
      boot({
        userId: intercom_settings.user_id,
        userHash: intercom_settings.user_hash,
      });
    }

    return () => {
      hardShutdown();
    };
  }, [boot, hardShutdown, intercom_settings, isPortalOwner]);

  useEffect(() => {
    setShowTermsModal(isAuthenticated && domainTerms?.terms !== null);
  }, [domainTerms, setShowTermsModal, isAuthenticated]);

  return (
    <>
      <SignInModal />
      {isAuthenticated && <TermsModal />}

      {/* user authenticated or login modal is visible */}
      {isAuthenticated || showSessionExpirationModal ? (
        <Outlet />
      ) : (
        <Navigate
          to={`${URLS.login}?${queryParameters.redirect}=${encodeURIComponent(
            location.pathname + location.search,
          )}`}
        />
      )}
    </>
  );
};

export default PrivateRoute;
