import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { tocTopStyles } from "./styles";

export type topProps = {
  isOpen: boolean;
};

const Top: FC<topProps> = ({ isOpen, children }) => {
  return <div css={(theme): SerializedStyles => tocTopStyles(theme, { isOpen })}>{children}</div>;
};

export default Top;
