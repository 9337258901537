import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { EditIconSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type EditActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const EditAction: FC<EditActionProps> = ({
  tooltipContent,
  icon: Icon = EditIconSVG,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.edit")}
      onClick={onClick}
      data-testid="edit-action"
      {...rest}
    >
      <Icon height={32} />
    </ActionButton>
  );
};

export default EditAction;
