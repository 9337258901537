import React, { FC } from "react";
import { tocListContainerStyles } from "./styles";
import classNames from "classnames";

export type ListContainerProps = {
  isOpen: boolean;
};

const className = (isOpen: boolean): string =>
  classNames("scrollable-container", { hidden: !isOpen });

const ListContainer: FC<ListContainerProps> = ({ isOpen, children }) => (
  <div className={className(isOpen)} css={tocListContainerStyles}>
    {children}
  </div>
);

export default ListContainer;
